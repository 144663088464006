import React, { useEffect, useState } from 'react';
import { DisabledDashboard, SelectionsBanner } from 'waypoint-react';
import {
    useGetGroupableAttributes,
    useSelectedDataLevel,
    useUnitMix,
    useGridConfigSettings,
} from 'waypoint-hooks';
import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import UnitMixTable from './UnitMixTable';
import {
    AppFeaturePermissions,
    AttributeFromAPI,
    FilterReferenceTypes,
} from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { Card } from 'antd';
import { css } from 'emotion';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { AttributesGroupBySelect } from 'components/attributesGroupBySelect/AttributesGroupBySelect';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
} from 'components/saved-configurations';
import { SavedConfigFilterType } from 'waypoint-types';

interface UnitMixCardProps {
    entityCodes: string[];
    hidePropertyColumns?: boolean;
    attributeSelection?: AttributeFromAPI | null;
    groupingSelection?: EntityDataGroupingKeys;
}

const cardTitle = css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
`;

const UnitMixCard = ({
    entityCodes,
    hidePropertyColumns,
}: UnitMixCardProps) => {
    const groupableAttributes = useGetGroupableAttributes();

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        setGridConfig,
        localConfig,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.UnitMix);

    const [groupingSelection, setGroupingSelection] =
        useState<EntityDataGroupingKeys | null>(null);

    const [attributeSelection, setAttributeSelection] =
        useState<AttributeFromAPI | null>(groupableAttributes[0] ?? null);

    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>([]);
    const selectedDataLevel = useSelectedDataLevel();

    const { unitMixData, recurringChargesData, isError } = useUnitMix({
        entityCodes,
        selectedDataLevel,
    });

    useEffect(() => {
        setAttributeSelection(groupableAttributes?.[0] ?? null);
    }, [groupableAttributes, setAttributeSelection]);

    useEffect(() => {
        if (!localConfig) {
            setLocalConfig({
                groupingSelection,
                attributeSelection,
            });
            return;
        }
        setLocalConfig({
            ...localConfig,
            groupingSelection,
            attributeSelection,
        });
    }, [groupingSelection, attributeSelection]);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration?.filters_json?.local_config;
            if (config['groupingSelection']) {
                setGroupingSelection(config['groupingSelection']);
            }
            if (config['attributeSelection']) {
                setAttributeSelection(config['attributeSelection']);
            }
        } else {
            setGroupingSelection(null);
            setAttributeSelection(groupableAttributes[0] ?? null);
        }
    }, [
        selectedConfiguration,
        configKey,
        groupableAttributes,
        setGroupingSelection,
        setAttributeSelection,
    ]);

    const onChange = (value: string[]) => {
        setSelectedChargeCode(value);
    };

    if (isError) {
        return <DisabledDashboard text={'Error loading data'} />;
    }

    if (!recurringChargesData || !unitMixData) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const { summary } = recurringChargesData;

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.UnitMix}>
            <SelectionsBanner
                isCollapsed={false}
                data-testid="unit-mix-selections-banner"
                bannerInlineStyles={{
                    padding: 16,
                    boxShadow: 'none',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flex: 1,
                        flexWrap: 'wrap',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingBottom: '3px',
                        }}
                    >
                        <SavedGridConfigSelect
                            savedConfigurationsData={savedConfigurationsData}
                            selectedConfiguration={selectedConfiguration}
                            setSelectedConfiguration={setSelectedConfiguration}
                        />
                        <span>
                            <SavedConfigManageDropdown
                                onUpdateConfig={onUpdateConfig}
                                onDeleteConfig={onDeleteConfig}
                                resetSelectedConfiguration={
                                    resetSelectedConfiguration
                                }
                                hasUserAccess={
                                    !isAdmin &&
                                    selectedConfiguration?.reference_type !==
                                        FilterReferenceTypes.USER
                                }
                                allowDeleteOnly={saveConfigDisabled}
                                defaultConfigSelected={!selectedConfiguration}
                                setIsEditorOpen={setIsEditorOpen}
                            />
                        </span>
                        {isEditorOpen && (
                            <SavedConfigEditor
                                handleClose={() => setIsEditorOpen(false)}
                                onSave={onSaveConfig}
                                isAdmin={isAdmin}
                                existingConfigNames={existingConfigNames}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            width: '450px',
                            marginRight: '35px',
                            marginLeft: '18px',
                            paddingBottom: '3px',
                        }}
                    >
                        {!hidePropertyColumns && (
                            <AttributesGroupBySelect
                                key={`${groupingSelection}_${
                                    attributeSelection?.key ?? ''
                                }`}
                                groupingSelection={groupingSelection}
                                setGroupingSelection={setGroupingSelection}
                                attributeSelection={attributeSelection}
                                attributes={groupableAttributes}
                                setAttributeSelection={setAttributeSelection}
                            />
                        )}
                    </div>

                    <div
                        style={{
                            paddingBottom: '3px',
                            marginLeft: 'auto',
                            marginRight: 12,
                        }}
                    >
                        <RecurringChargeSelect
                            recurringCharges={summary.buckets}
                            selectedChargeCodes={selectedChargeCode}
                            onChange={onChange}
                            style={{
                                width: 'auto',
                                minWidth: '200px',
                            }}
                        />
                    </div>
                </div>
            </SelectionsBanner>
            <Card
                data-testid="unit-mix-card"
                title={
                    <div className={cardTitle}>
                        <h2
                            style={{
                                margin: '0 10px 0 0',
                            }}
                        >
                            Unit Mix
                        </h2>
                    </div>
                }
            >
                <UnitMixTable
                    key={`${selectedConfiguration?.id ?? ''}_${
                        configKey ?? ''
                    }`}
                    entityCodes={entityCodes}
                    selectedDataLevel={selectedDataLevel}
                    hidePropertyColumns={hidePropertyColumns}
                    selectedChargeCode={selectedChargeCode}
                    attributeSelection={attributeSelection}
                    groupingSelection={groupingSelection}
                    selectedConfiguration={selectedConfiguration}
                    setGridConfig={setGridConfig}
                    setLocalConfig={setLocalConfig}
                />
            </Card>
        </PermissionedWrapper>
    );
};

export default UnitMixCard;
