import {
    COMPLETE,
    HIERARCHY_APPROVAL,
    IN_PROGRESS,
    ReportWorkflowReviewStatus,
    WidgetTypes,
} from './constants';
import {
    EntityReportForGrid,
    UpdatedEntityReport,
    LocalReportItem,
    ReportWidget,
    EntityReportWidget,
    EntityReportWidgetListItem,
    ReportSetting,
    OptionType,
    ReportWorkflowReview,
    EntityReportApproval,
    MentionableDataSource,
    WorkflowRoleList,
} from 'waypoint-types';
import { DASH_DASH } from 'config/constants';
import { format, parseISO } from 'date-fns';
import { Dictionary } from 'ts-essentials';
import {
    EntityReport,
    Report,
    User,
    ReportGridType,
    PropertyType,
} from 'waypoint-types';
import { safeDivision } from 'shared-types';
import { Tag } from 'antd';
import theme from 'config/theme';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { numericalSort } from 'utils/tables/sorters';
import { VirtualPDFComponentFactory } from 'waypoint-utils/pdf/VirtualPDFRenderer';
import { NarrativePDFWrapper } from 'components/reports/components/entity-report-widgets/NarrativePDFWrapper';
import { SelectedEntityReportWidget } from 'components/reports/components/entity-report-widgets/EntityReportWidgetUtils';
import { AppFeaturePermissions, allAccessPermissions } from 'contexts';
import { Link } from 'react-router-dom';
import StepsWorkflowAvatarGroup from 'components/financials/workflow-roles/StepsWorkflowAvatarGroup';
import { WorkflowAvatarGroup } from 'components/financials/workflow-roles';
import { formatInTimeZone } from 'date-fns-tz';
import { css } from 'emotion';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';
import { RefObject } from 'react';

export const REPORT_COMPLETE = 'Complete';
export const REPORT_INCOMPLETE = 'Incomplete';
export const REPORT_ACTIVE = 'Active';
export const REPORT_DRAFT = 'Draft';
export const REPORT_ARCHIVED = 'Archived';

export const ENTITY_CODE_KEY = 'entity_code';
export const REPORT_ID_KEY = 'report_id';
export const COMMENT_ID_QUERY_KEY = 'comment_id';
export const WIDGET_ID_KEY = 'widget_id';
export const FROM_MY_REPORTS_KEY = 'from_my_reports';
export const ENTITY_REPORT_PUBLISHED_ID_KEY = 'pub_id';

interface CalculatedProgress {
    complete: number;
    total: number;
}

interface WorkflowList {
    userId: number;
    userName: string;
    profile_image_url?: string;
    hierarchy_index?: number;
}

interface ReportProgress {
    complete: number;
    complete_percentage: string;
}

type SettingsVerificationFunction = (settings: any) => boolean;

export interface EntityReportWidgetPdfSettings {
    landscape: boolean;
    paperSize: string;
}

export const ReportGridCardStyle = css`
    border: 0;
    z-index: 1;
    > .ant-card-head {
        padding: 10px !important;
        border-radius: 0;
        font-size: 16px;
        border: 1px solid ${theme.colors.grays.light};
    }
`;

export const ReportBottomNavigationCardStyle = css`
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 0 25px;
    border: 1px solid ${theme.colors.grays.light};
    border-top: none;
    background-color: white;
    bottom: 0;

    .left-buttons {
        display: flex;
        justify-content: flex-start;
        width: 33%;
    }

    .center-buttons {
        display: flex;
        justify-content: center;
        width: 34%;
    }

    .right-buttons {
        display: flex;
        justify-content: flex-end;
        width: 33%;
    }
`;

export const ReportBasicsCardStyle = (workflowContainerHeight: number) => css`
    width: 100%;
    margin: 6px 0 0 0;
    border: 1px solid ${theme.colors.grays.light};
    border-radius: 0;
    height: ${workflowContainerHeight}px;
    .ant-card-head {
        font-size: 16px;
        margin-bottom: 12px;
        border-bottom: 1px solid ${theme.colors.grays.light};
    }
`;

export const ReportDetailCardStyle = (
    workflowContainerHeight: number | string,
) => css`
    width: 100%;
    height: ${workflowContainerHeight === '100%'
        ? '100%'
        : `${workflowContainerHeight}px`};
    margin: 6px 0 0 0;
    border-radius: 0;
    border: 0;
    > .ant-card-head {
        font-size: 16px;
        border-bottom: 1px solid ${theme.colors.grays.light};
        border-radius: 0;
        border: 1px solid ${theme.colors.grays.light};
        padding: 0 10px !important;
    }
    > .ant-card-body {
        height: ${workflowContainerHeight === '100%'
            ? '100%'
            : `${(workflowContainerHeight as number) - 7}px`};
        overflow: scroll;
        border: 1px solid ${theme.colors.grays.light};
        border-top: none;
        border-radius: 0;
        padding: 10px !important;
    }
    > .ant-card {
        padding: 20px;
    }
    > .ant-tabs-nav {
        border-bottom: 1px solid #cccccc;
    }
    > .ant-tabs-nav::before {
        border-bottom: none !important;
    }
    > .ant-tabs-top {
        margin-left: 14px;
    }
`;

export const ReportWorkflowTabsStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;

    .report-title {
        padding: 0 20px;
    }

    .steps-container {
        position: sticky;
        padding: 20px 20px 0 20px;
        justify-content: center;
        align-items: center;
    }

    .steps-container .ant-steps-horizontal {
        border: 1px solid ${theme.colors.grays.light};
        padding: 18px 30px;
    }

    .steps-scroll {
        overflow: auto;
    }

    .steps-content {
        flex: 1;
        padding: 10px 0 20px 0;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
`;

export const filterReportsByEntityCodes = (
    data: Report[],
    entityCodes: string[],
) => {
    data?.forEach((item) => {
        item.entityReports =
            item.entityReports?.filter((report) =>
                entityCodes.includes(report.entity_code),
            ) ?? [];
    });
    return data;
};

export const componentVerifications: Record<
    string,
    SettingsVerificationFunction
> = {
    swot: (settings) => !!settings?.selectedYears?.length,
    leasing_guidelines: (settings) => !!settings?.selectedYear?.length,
    priorities_and_objectives: (settings) => !!settings?.selectedYears?.length,
    hold_sell: (settings) => !!settings?.selectedYears?.length,
    market_overview: (settings) => !!settings?.selectedYears?.length,
    leasing_plan: (settings) => !!settings?.planYear,
};

export const reportHasCorrectSettings = (item: LocalReportItem) => {
    const verify = componentVerifications[item.component];
    return verify ? verify(item.settings_inputs) : false;
};

export const renderDueDate = (cellData: { data: { due_date: string } }) => {
    return cellData.data.due_date
        ? format(new Date(cellData.data.due_date ?? ''), 'MMMM d, yyyy')
        : DASH_DASH;
};

export const decorateReportsForGrid = (
    reports: Report[],
    entityCodes: string[],
    entityReportWidgets: EntityReportWidget[],
): ReportGridType[] => {
    const filteredReports = filterReportsByEntityCodes(reports, entityCodes);

    return filteredReports.map((report) => {
        const reportProgress = calculateReportProgress(
            report.entityReports,
            entityReportWidgets,
        );

        return {
            id: report.id,
            report_name: report.name,
            report_status:
                report.state.charAt(0).toUpperCase() + report.state.slice(1),
            properties: report.entityReports?.length ?? 0,
            createdByUser: getUpdatedByName(report.createdByUser),
            updatedByUser: getUpdatedByName(
                report.updatedByUser,
                report.timestamps.updated_at,
            ),
            complete: reportProgress.complete,
            complete_percentage: reportProgress.complete_percentage,
            entityReports: report.entityReports,
            due_date: report.due_date,
            deleted_at: report.deleted_at,
            updated_at: report.timestamps.updated_at,
            createdBy: report.created_by,
        };
    });
};

const getPropertyName = (
    properties: Dictionary<PropertyType>,
    value: string,
) => {
    return (
        Object.values(properties)
            .map((p) => {
                const entry = {
                    value: p.id,
                    label: p.display_name,
                };
                return entry;
            })
            .find((p) => p.value === value)?.label ?? ''
    );
};

export const decorateEntityReportsForGrid = (
    reports: Report[] | undefined,
    properties: Dictionary<PropertyType>,
    entityReportWidgets: EntityReportWidget[],
    approvals: EntityReportApproval[] = [],
): EntityReportForGrid[] => {
    if (!reports) {
        return [];
    }

    const allEntityReports = reports
        .flatMap((report) => report.entityReports)
        .filter(Boolean);

    return allEntityReports.map((item) => {
        const entityReportApprovals = approvals.find(
            (approval) => approval.entityReportId === item.id,
        );

        const relatedReport = reports.find((r) => r.id === item.report_id);
        const approvalSettings = relatedReport?.reportSettings?.find(
            (rs) => rs.settings_type === 'approvals',
        );

        return {
            ...item,
            report_name: relatedReport?.name ?? '',
            property: getPropertyName(properties, item.entity_code),
            report_status:
                entityReportApprovals?.status ??
                ReportWorkflowReviewStatus.Open,
            reviews: entityReportApprovals?.reviews ?? [],
            progress: calculateEntityReportProgress(
                [item],
                entityReportWidgets,
            ),
            formatted_due_date: item.due_date
                ? format(new Date(item.due_date), 'MMMM d, yyyy')
                : DASH_DASH,
            has_approvals: !!approvalSettings ?? false,
            approval_type: approvalSettings?.settings?.type ?? null,
        };
    });
};

export const filterAndDecorateReports = (
    reportsRawData: Report[] | undefined,
    entityCodes: string[],
    entityReportWidgets: EntityReportWidget[],
    createdBy: number | null,
    status: string | null,
) => {
    const decoratedReports = decorateReportsForGrid(
        reportsRawData ?? [],
        entityCodes,
        entityReportWidgets ?? [],
    );

    return decoratedReports.filter(
        (report) =>
            (createdBy === null || report.createdBy === createdBy) &&
            (status === null || report.report_status === status),
    );
};

export const decorateReportWidgets = (
    reportWidgets: ReportWidget,
): LocalReportItem => {
    return {
        component: reportWidgets.widget_type,
        narrativeEnabled: !!reportWidgets.narrative_position,
        instructionsEnabled: !!reportWidgets.instructions,
        narrative: reportWidgets.narrative_position,
        ...reportWidgets,
    };
};

export const getReportStatusColor = (type: string) => {
    const colors: Record<string, string> = {
        [REPORT_ACTIVE]: theme.colors.green,
        [REPORT_DRAFT]: theme.colors.blues.outline,
        [REPORT_ARCHIVED]: theme.colors.grays.medium,
    };
    const status = type.slice(0, 1).toUpperCase() + type.slice(1);
    return colors[status];
};

const getUpdatedWorkflowRoles = (
    update: UpdatedEntityReport,
    entityReport: EntityReport,
) => {
    type WorkflowRole = {
        user_id: number;
        report_role: string;
    };
    const workflowRole: WorkflowRole[] = [];
    if (update.assignee.length > 0) {
        workflowRole.push(
            ...update.assignee.map((userId) => {
                return {
                    user_id: Number(userId),
                    report_role: 'assignee',
                };
            }),
        );
    } else {
        workflowRole.push(
            ...entityReport.workflowRole
                .filter((role) => role.report_role === 'assignee')
                .map((role) => {
                    return {
                        user_id: role.user_id,
                        report_role: 'assignee',
                    };
                }),
        );
    }
    if (update.reviewer.length > 0) {
        workflowRole.push(
            ...update.reviewer.map((userId) => {
                return {
                    user_id: Number(userId),
                    report_role: 'reviewer',
                };
            }),
        );
    }

    if (!update.reviewer.length && entityReport.workflowRole) {
        workflowRole.push(
            ...entityReport.workflowRole
                .filter((role) => role.report_role === 'reviewer')
                .map((role) => {
                    return {
                        user_id: role.user_id,
                        report_role: 'reviewer',
                    };
                }),
        );
    }
    return workflowRole;
};
export const updateReportEntityReports = (
    reports: Report[],
    update: UpdatedEntityReport,
) => {
    return reports.map((report) => {
        if (report.id === update.reportId) {
            return {
                ...report,
                entityReports: report.entityReports?.length
                    ? report.entityReports?.map((entityReport) => {
                          if (
                              update.selectedReports.includes(entityReport.id)
                          ) {
                              const dueDate = update.dueDate
                                  ? update.dueDate.toDate()
                                  : entityReport.due_date;
                              return {
                                  ...entityReport,
                                  due_date: dueDate,
                                  workflowRole: getUpdatedWorkflowRoles(
                                      update,
                                      entityReport,
                                  ),
                              };
                          }
                          return entityReport;
                      })
                    : [],
            };
        }
        return report;
    });
};

export const calculateReportProgress = (
    entityReports: EntityReport[],
    entityReportWidgets: EntityReportWidget[],
): ReportProgress => {
    const result = entityReports?.reduce(
        (acc, entityReport) => {
            const status = calculateEntityReportStatus(
                [entityReport],
                entityReportWidgets,
            );
            if (status === REPORT_COMPLETE) {
                acc.complete += 1;
            } else if (status === REPORT_INCOMPLETE) {
                acc.incomplete += 1;
            }
            return acc;
        },
        { complete: 0, incomplete: 0 },
    ) ?? { complete: 0, incomplete: 0 };

    const percentage =
        safeDivision(result.complete, result.complete + result.incomplete) *
        100;
    return {
        complete: result.complete,
        complete_percentage: percentage.toFixed(0),
    };
};

interface VirtualPDFComponentFactoriesParams {
    widgetsToExport: EntityReportWidgetListItem[];
    entityName: string;
    entity: PropertyType | undefined;
    asOfDate: Date | undefined;
    entityCode: string;
    entityReport: EntityReport | null;
    report?: Report;
    reportHasLogo?: boolean;
}

export function getVirtualPDFComponentFactories({
    widgetsToExport,
    entityName,
    entity,
    asOfDate,
    entityCode,
    entityReport,
    report,
    reportHasLogo,
}: VirtualPDFComponentFactoriesParams): VirtualPDFComponentFactory[] {
    return widgetsToExport.map((widget) => {
        const pdfSettings = {
            paperSize: widget.paper_size === 'legal' ? 'Legal' : 'Letter',
            landscape: widget.orientation === 'landscape',
        };

        return (ref) => {
            return (
                <NarrativePDFWrapper
                    widget={widget}
                    pdfSettings={pdfSettings}
                    pdfTemplateParams={{
                        propertyName: entityName,
                        reportName: report?.name ?? '',
                        reportHasLogo: reportHasLogo,
                    }}
                >
                    <SelectedEntityReportWidget
                        key={widget?.entityReportWidgetId ?? ''}
                        asOfDate={asOfDate ?? new Date()}
                        entityCode={entityCode}
                        widgetId={widget?.entityReportWidgetId ?? ''}
                        widgetType={widget?.widget_type ?? ''}
                        widgetSettings={widget?.settings_inputs ?? {}}
                        narrativePosition={widget?.narrative_position ?? ''}
                        narrativeText={widget?.narrative_text ?? ''}
                        exportRef={ref}
                        isPDFExport={true}
                        pdfSettings={pdfSettings}
                        propertyInfo={entity}
                        entityReport={entityReport}
                        report={report}
                    />
                </NarrativePDFWrapper>
            );
        };
    });
}

export const getNarrativeText = (
    reportWidget: ReportWidget,
    entityReportWidget: EntityReportWidget | undefined,
) => {
    if (entityReportWidget?.narrative_text) {
        return entityReportWidget?.narrative_text;
    }
    if (reportWidget.widget_type === 'narrative') {
        const widgetSettings =
            reportWidget?.settings_inputs as Dictionary<string>;
        return widgetSettings?.content ?? '';
    }
    return '';
};

export function getWidgetListItemsFromReport(
    entityCode: string,
    report: Report,
    coverPageSettings: ReportSetting | undefined,
    includeTableOfContents: boolean,
): EntityReportWidgetListItem[] {
    const entityReport =
        report.entityReports?.find((er) => er.entity_code === entityCode) ??
        null;

    const widgetList = report.reportWidgets;

    if (coverPageSettings?.enabled) {
        if (includeTableOfContents) {
            const tableOfContentsWidget = createTableOfContentsWidget(
                coverPageSettings?.settings?.paperOrientation,
                coverPageSettings?.settings?.paperSize,
            );
            widgetList.unshift(tableOfContentsWidget);
        }
        const coverPageWidget = createCoverPageWidget(
            coverPageSettings?.settings?.paperOrientation,
            coverPageSettings?.settings?.paperSize,
            includeTableOfContents,
        );
        widgetList.unshift(coverPageWidget);
    }

    return widgetList
        .map((reportWidget) => {
            const entityReportWidget = entityReport?.entityReportWidgets?.find(
                (widget) => widget.report_widget_id === reportWidget.id,
            );
            return {
                ...reportWidget,
                entityReportWidgetId: entityReportWidget?.id ?? '',
                narrative_text: getNarrativeText(
                    reportWidget,
                    entityReportWidget,
                ),
                comment_thread_id:
                    entityReportWidget?.reportMetadata.comment_thread_id ?? '',
                reportMetadata: entityReportWidget?.reportMetadata,
                status: entityReportWidget?.status,
            };
        })
        .sort((a, b) => numericalSort(a.sort_index, b.sort_index));
}

const getEntityReportWidgets = (
    entityReportId: string,
    entityReportWidgets: EntityReportWidget[],
) => {
    return entityReportWidgets.filter(
        (widget) => widget.entity_report_id === entityReportId,
    );
};

export const calculateEntityReportStatus = (
    entityReports: EntityReport[],
    entityReportWidgets: EntityReportWidget[],
): string => {
    const progress = calculateProgress(entityReports, entityReportWidgets);
    return progress.complete === progress.total
        ? REPORT_COMPLETE
        : REPORT_INCOMPLETE;
};

export const calculateEntityReportProgress = (
    entityReports: EntityReport[],
    entityReportWidgets: EntityReportWidget[],
): string => {
    const progress = calculateProgress(entityReports, entityReportWidgets);
    return `${progress.complete}/${progress.total} Sections Complete`;
};

const calculateProgress = (
    entityReports: EntityReport[],
    entityReportWidgets: EntityReportWidget[],
): CalculatedProgress => {
    return entityReports?.reduce(
        (acc, entityReport) => {
            const entityReportWidgetsById = getEntityReportWidgets(
                entityReport.id,
                entityReportWidgets,
            );
            const status = entityReportWidgetsById.flatMap(
                (widget) => widget.status,
            );
            if (status.length > 0) {
                acc.complete += status.filter((st) => st === COMPLETE).length;
                acc.total += status.length;
            }
            return acc;
        },
        { complete: 0, total: 0 },
    );
};

const getUpdatedByName = (user: User | null, updated_at?: string) => {
    if (!user) {
        return DASH_DASH;
    }

    const name = `${user.firstname} ${user.lastname}`;
    if (updated_at) {
        const date = format(new Date(updated_at), 'MMMM d, yyyy @ h:mma');
        return `${name} on ${date}`;
    }
    return name;
};

export const getWorkflowRole = (
    entityReports: EntityReport,
    userData: MentionableDataSource[] | undefined,
    roleType: string,
): WorkflowList[] => {
    const workflowRoleIds = entityReports?.workflowRole
        ?.filter((role) => role.report_role === roleType)
        .map((role) => role.user_id);

    const filteredUsers =
        userData?.filter((user) =>
            workflowRoleIds?.includes(Number(user.id)),
        ) ?? [];

    return filteredUsers.map((user) => {
        const userRole = entityReports?.workflowRole?.find(
            (role) => role.report_role === roleType && role.user_id === user.id,
        );

        return {
            userName: user.text,
            userId: Number(user.id),
            profile_image_url: user?.profile_image_url,
            hierarchy_index: userRole?.hierarchy_index ?? 0,
        };
    });
};

export const getUserOptions = (users: User[]) => {
    return Object.values(users).map((user) => ({
        value: user.id,
        label: `${user.firstname} ${user.lastname}`,
    }));
};

interface WorkflowRole {
    userId: number;
    entityReport: EntityReport | null;
}

export const userWorkflowRole = ({ userId, entityReport }: WorkflowRole) => {
    const workflowRolesList: WorkflowRoleList = [];
    if (!userId || !entityReport?.workflowRole) {
        return workflowRolesList;
    }

    const reviewers = entityReport?.workflowRole.filter(
        (row) => row.report_role === WORKFLOW_REVIEWER,
    );
    const assignees = entityReport?.workflowRole.filter(
        (row) => row.report_role === WORKFLOW_ASSIGNEE,
    );

    const isUserReviewer = reviewers?.find((wr) => wr.user_id === userId);
    if (isUserReviewer) {
        workflowRolesList.push(WORKFLOW_REVIEWER);
    }

    const isUserAssignee = assignees?.find((wa) => wa.user_id === userId);

    if (isUserAssignee) {
        workflowRolesList.push(WORKFLOW_ASSIGNEE);
    }

    return workflowRolesList;
};

export const checkIfValidUUID = (uuid: string) => {
    const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(uuid);
};

const createRenderTagColorStatus =
    (colorMap: Record<string, string>) =>
    (cellInfo: Dictionary<ReportGridType>) => {
        const state = cellInfo.data.report_status;
        const getStatusColor = (type: string) => {
            return colorMap[type] ?? theme.colors.grays.medium;
        };
        return (
            <Tag
                color={getStatusColor(state)}
                style={{
                    width: 'max-content',
                    minWidth: '80px',
                    borderRadius: '8px',
                    textAlign: 'center',
                }}
            >
                {state}
            </Tag>
        );
    };

export const renderReportStatus = createRenderTagColorStatus({
    [REPORT_COMPLETE]: theme.colors.green,
    [REPORT_INCOMPLETE]: theme.colors.red,
    [REPORT_ACTIVE]: theme.colors.green,
    [REPORT_DRAFT]: theme.colors.blues.antBlue,
});

export const publishedReportStausColorMap = {
    [ReportWorkflowReviewStatus.Approved]:
        theme.colors.workflowReportStatus.approved,
    [ReportWorkflowReviewStatus.Rejected]: theme.colors.red,
    [ReportWorkflowReviewStatus.InProgress]:
        theme.colors.workflowReportStatus.in_progress,
    [ReportWorkflowReviewStatus.InReview]:
        theme.colors.workflowReportStatus.ready_for_review,
    [ReportWorkflowReviewStatus.Open]: theme.colors.workflowReportStatus.open,
};

export const renderPublishedReport = createRenderTagColorStatus(
    publishedReportStausColorMap,
);

export const renderReportNameCell = (cellData: { data: ReportGridType }) => {
    return (
        <Link to={`/report/manager/${cellData.data.id}`}>
            {cellData.data.report_name}
        </Link>
    );
};

export const renderPercentage = (cellInfo: Dictionary<ReportGridType>) => {
    return `${cellInfo.data.complete_percentage}%` ?? DASH_DASH;
};

export const calculatedProgress = (reportWidgetList: EntityReportForGrid[]) => {
    const complete = reportWidgetList.filter(
        (item) => item.status.toLowerCase() === COMPLETE,
    ).length;
    const inProgress = reportWidgetList.filter(
        (item) => item.status.toLowerCase() === IN_PROGRESS,
    ).length;
    const percentage =
        safeDivision(complete + inProgress, reportWidgetList.length) * 100;

    return Number(percentage.toFixed(1));
};

export const calculateEntityItems = (
    data: Report[],
    properties: Dictionary<PropertyType>,
    entityReportWidgets: EntityReportWidget[],
) => decorateEntityReportsForGrid(data, properties ?? {}, entityReportWidgets);

export function filterReportsByUserRole(
    data: Report[],
    userId: number,
    status: string,
) {
    const assigneeReports: Report[] = [];
    const reviewerReports: Report[] = [];

    data.forEach((report) => {
        if (report.state !== status) {
            return;
        }

        const assigneeEntityReports: EntityReport[] = [];
        const reviewerEntityReports: EntityReport[] = [];

        report.entityReports?.forEach((entityReport) => {
            const hasAssigneeRole = entityReport.workflowRole?.some(
                (role) =>
                    role.user_id === userId && role.report_role === 'assignee',
            );
            if (hasAssigneeRole) {
                assigneeEntityReports.push(entityReport);
            }

            const hasReviewerRole = entityReport.workflowRole?.some(
                (role) =>
                    role.user_id === userId && role.report_role === 'reviewer',
            );

            if (hasReviewerRole) {
                reviewerEntityReports.push(entityReport);
            }
        });

        if (assigneeEntityReports.length) {
            assigneeReports.push({
                ...report,
                entityReports: assigneeEntityReports,
            });
        }

        if (reviewerEntityReports.length) {
            reviewerReports.push({
                ...report,
                entityReports: reviewerEntityReports,
            });
        }
    });

    return {
        assigneeReports,
        reviewerReports,
    };
}

export const applyGrouping = (
    dataGrid: RefObject<DataGridRef<any, any>> | null,
    group: string,
) => {
    if (!dataGrid) {
        return;
    }

    dataGrid?.current?.instance().clearGrouping();
    dataGrid?.current?.instance().columnOption(group, 'groupIndex', 0);
    dataGrid?.current?.instance().refresh();
};

export const clearGrouping = (
    dataGrid: RefObject<DataGridRef<any, any>> | null,
) => {
    dataGrid?.current?.instance().clearGrouping();
};

export const getEntityReportWidgetsForSelectedReport = (
    selectedReports: ReportGridType[],
    entityReportWidgets: EntityReportWidget[],
) => {
    if (!entityReportWidgets) {
        return [];
    }

    const allRelevantEntityReportIds = selectedReports.flatMap((report) =>
        report.entityReports?.length
            ? report.entityReports?.map((entityReport) => entityReport.id)
            : [],
    );

    return entityReportWidgets.filter((widget) =>
        allRelevantEntityReportIds.includes(widget.entity_report_id),
    );
};

export const getDefaultSettings = () => ({
    cover_page: {
        paperSize: 'letter',
        paperOrientation: 'portrait',
        propertyPhoto: false,
        dueDate: false,
        assignees: false,
        reviewers: false,
    },
    footer: {
        document_upload_id: null,
    },
});

export const createCoverPageWidget = (
    orientation = 'landscape',
    paper_size = 'letter',
    includeTableOfContents: boolean,
) => {
    return {
        orientation,
        paper_size,
        id: WidgetTypes.CoverPage,
        name: 'Cover Page',
        sort_index: includeTableOfContents ? -2 : -1,
        widget_type: WidgetTypes.CoverPage,
        settings_inputs: {},
        entityReportWidgetId: WidgetTypes.CoverPage,
        narrative_text: '',
        comment_thread_id: '',
        status: '',
        report_id: '',
        instructions: '',
        narrative_position: 'above',
        reportMetadata: undefined,
        timestamps: {
            created_at: '',
            updated_at: '',
        },
        page_break: true,
    };
};

export const createTableOfContentsWidget = (
    orientation = 'landscape',
    paper_size = 'letter',
) => {
    return {
        orientation,
        paper_size,
        id: WidgetTypes.TableOfContents,
        name: 'Table of Contents',
        sort_index: -1,
        widget_type: WidgetTypes.TableOfContents,
        settings_inputs: {},
        entityReportWidgetId: WidgetTypes.TableOfContents,
        narrative_text: '',
        comment_thread_id: '',
        status: '',
        report_id: '',
        instructions: '',
        narrative_position: 'above',
        reportMetadata: undefined,
        timestamps: {
            created_at: '',
            updated_at: '',
        },
        page_break: true,
    };
};

export const filterWidgetsWithPermissions = (
    featurePermissions: string[],
    reportWidget: ReportWidget,
) => {
    const widgetPermission = reportWidget.widget_type as AppFeaturePermissions;

    // If the widget is 'recurring_charge_summary' and 'rent_roll' is not in permissions, filter it out
    if (
        reportWidget.widget_type === 'recurring_charge_summary' &&
        !featurePermissions.includes(AppFeaturePermissions.RentRoll)
    ) {
        return false;
    }

    // If the widget is 'capital_plan' or 'capital_projects' and 'capital_projects' is not in permissions, filter them out
    if (
        (reportWidget.widget_type === 'capital_plan' ||
            reportWidget.widget_type ===
                AppFeaturePermissions.CapitalProjects) &&
        !featurePermissions.includes(AppFeaturePermissions.CapitalProjects)
    ) {
        return false;
    }

    return (
        !allAccessPermissions.includes(widgetPermission) ||
        featurePermissions.includes(widgetPermission) ||
        reportWidget.widget_type === WidgetTypes.CoverPage
    );
};

export const generateUniqueOptions = (
    data: Report[] | undefined,
    valueAccessor: (report: Report) => string | number,
    labelAccessor: (report: Report) => string,
): OptionType[] => {
    const uniqueSet = new Set<string>();
    const uniqueOptions: OptionType[] = [];

    data?.forEach((item: Report) => {
        const value = valueAccessor(item);
        const label = labelAccessor(item);
        const key = `${value}-${label}`;

        if (!uniqueSet.has(key)) {
            uniqueSet.add(key);
            uniqueOptions.push({ value, label });
        }
    });

    return uniqueOptions;
};

export const getAvatarBackgroundColor = (role: string, index: number) => {
    if (role === WORKFLOW_ASSIGNEE) {
        return theme.colors.workflowAvatarGroups.assignees[index];
    }
    return theme.colors.workflowAvatarGroups.reviewers[index];
};

export const getHierarchyIndexOfMostRecentReviewer = (
    reviews: ReportWorkflowReview[],
    workflowRoles: WorkflowList[],
): number => {
    if (!reviews.length) {
        return 0;
    }

    reviews.sort(
        (a, b) =>
            new Date(b?.timestamps.updated_at).getTime() -
            new Date(a?.timestamps.updated_at).getTime(),
    );

    const mostRecentReviewUserId = reviews[0].user_id;
    const user = workflowRoles.find(
        (role) => role.userId === mostRecentReviewUserId,
    );

    return user?.hierarchy_index ?? 0;
};

export const workflowGroupRenderer = (
    data: EntityReportForGrid,
    userData: MentionableDataSource[] | undefined,
    roleType: string,
) => {
    const isReviewerRoleAndHasApprovalsWorkflow =
        roleType !== WORKFLOW_ASSIGNEE && data.has_approvals;

    const roles = getWorkflowRole(data, userData, roleType);

    if (!roles.length) {
        return DASH_DASH;
    }

    const approvalIsHierarchical = data.approval_type === HIERARCHY_APPROVAL;

    const rolesWithApprovalStatus = isReviewerRoleAndHasApprovalsWorkflow
        ? roles.map((role) => ({
              ...role,
              approval_status: data.reviews?.find(
                  (review) => review.user_id === role.userId,
              )?.status,
          }))
        : roles;

    if (approvalIsHierarchical && roleType !== WORKFLOW_ASSIGNEE) {
        const currentReviewerStep = getHierarchyIndexOfMostRecentReviewer(
            data.reviews,
            roles,
        );

        return (
            <StepsWorkflowAvatarGroup
                currentReviewIndex={currentReviewerStep}
                roles={rolesWithApprovalStatus}
                roleType={roleType}
            />
        );
    }

    return (
        <WorkflowAvatarGroup
            withApprovals={isReviewerRoleAndHasApprovalsWorkflow}
            roleType={roleType}
            workflowRoleList={rolesWithApprovalStatus}
            size="small"
        />
    );
};

export const formatDate = (dateString: string) => {
    if (dateString === '') {
        return dateString;
    }
    return formatInTimeZone(parseISO(dateString), 'UTC', 'M/d/yyyy');
};
