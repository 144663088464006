import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useGetKPIs } from 'waypoint-hooks/data-access/useGetKPIs';
import {
    useGetAttributes,
    useGetDataAvailabilityDate,
    useGetSavedConfigurationById,
    useGetSavedConfigurations,
    useGetSelectedFilteredEntityCodes,
    useGetUserData,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import {
    AttributeDefinition,
    getAttributeDefinitionFromKPIRequest,
    getKPIRequestKey,
    KPIColumnMappings,
    KPIMetric,
} from 'shared-types';
import { DataGrid } from 'devextreme-react';

import {
    Column,
    Export,
    FilterPanel,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    Scrolling,
} from 'devextreme-react/data-grid';
import {
    Button,
    Card,
    Divider,
    Empty,
    Select,
    Skeleton,
    Space,
    Tooltip,
} from 'antd';
import {
    dataGridStyles,
    mapAttributeFormat,
    mapAttributeType,
} from 'components/attributes/card/utils';
import moment from 'moment/moment';
import { DatePicker } from 'waypoint-react';
import { Entity, SelectedDataLevel } from 'waypoint-types';
import { uniqBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { RouteUrls } from 'routes/RouteConstants';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { AppFeaturePermissions } from 'contexts/permissions-groups';

export function KPIGridPage(): ReactElement {
    const history = useHistory();

    const entityCodes = useGetSelectedFilteredEntityCodes();
    const selectedDataLevel = useSelectedDataLevel();

    const { userId, clientId } = useGetUserData();
    const { data: savedGrids } = useGetSavedConfigurations(
        userId,
        clientId,
        'kpis',
    );

    const { data: attributes } = useGetAttributes(entityCodes);
    const { gridId: gridIdFromURL } = useParams() ?? {};

    const [selectedGridId, setSelectedGridId] = useState<string | undefined>(
        gridIdFromURL,
    );

    useEffect(() => {
        if (!selectedGridId) {
            return;
        }

        history.push(RouteUrls.KPI_GRID.replace(':gridId', selectedGridId));
    }, [selectedGridId]);

    useEffect(() => {
        if (!gridIdFromURL) {
            return;
        }

        setSelectedGridId(gridIdFromURL);
    }, [gridIdFromURL]);

    const { data: gridSettings } = useGetSavedConfigurationById<KPIMetric[]>(
        selectedGridId ?? null,
    );

    const { data: asOfDate, isLoading: isLoadingAsOfDate } =
        useGetDataAvailabilityDate(entityCodes);
    const [date, setDate] = useState<Date>();

    useEffect(() => {
        setDate(asOfDate);
    }, [asOfDate]);

    const gridOptions = savedGrids?.map((grid) => {
        return {
            label: grid.name,
            value: grid.id,
        };
    });

    const gridSelect = (
        <Select
            value={selectedGridId}
            options={gridOptions}
            placeholder="Select a view"
            style={{ minWidth: '200px' }}
            onChange={(newGridId: string) => setSelectedGridId(newGridId)}
        />
    );

    const addViewButton = (
        <Tooltip title="Add new view">
            <Button type="primary" onClick={() => navigateToEditor()}>
                <PlusOutlined />
            </Button>
        </Tooltip>
    );

    const editViewButton = (
        <Tooltip title="Edit selected view">
            <Button
                type="primary"
                onClick={() => navigateToEditor(selectedGridId)}
            >
                <EditOutlined />
            </Button>
        </Tooltip>
    );

    function navigateToEditor(gridId?: string) {
        history.push(
            RouteUrls.KPI_GRID_EDITOR.replace(':gridId', gridId ?? 'new'),
        );
    }

    const noViewSelected = (
        <Empty description="No view selected. Please choose or add one from the menu in the top-right." />
    );

    const datePicker = (
        <DatePicker
            picker="month"
            style={{ width: '150px' }}
            format={'MMMM YYYY'}
            value={moment(date)}
            allowClear={false}
            disabledDate={(momentDate) => momentDate.isAfter(asOfDate)}
            onChange={(value: moment.Moment) => {
                if (value) {
                    setDate(value.toDate());
                }
            }}
            placeholder="Select month"
        />
    );

    const extra = (
        <Space direction="horizontal">
            {gridSelect}
            {addViewButton}
            {selectedGridId ? editViewButton : null}

            <Divider type="vertical" />

            {datePicker}
        </Space>
    );

    return (
        <Card
            title={`KPIs${gridSettings ? ` - ${gridSettings.name}` : ''}`}
            style={{ width: '100%' }}
            extra={extra}
        >
            <PermissionedWrapper
                featureKey={AppFeaturePermissions.KPIs}
                showDisabledView={true}
            >
                {isLoadingAsOfDate ||
                !date ||
                !attributes ||
                (selectedGridId && !gridSettings) ||
                !savedGrids ? (
                    <Skeleton />
                ) : !selectedGridId ? (
                    noViewSelected
                ) : (
                    <KPIGrid
                        date={date}
                        selectedDataLevel={selectedDataLevel}
                        entityCodes={entityCodes}
                        metrics={gridSettings?.filters_json ?? []}
                        attributeDefinitions={attributes.attributeDefinitions}
                        entities={attributes.entities}
                        title={gridSettings?.name ?? ''}
                    />
                )}
            </PermissionedWrapper>
        </Card>
    );
}

interface KPIGridProps {
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    date: Date;
    metrics: KPIMetric[];
    attributeDefinitions: AttributeDefinition[];
    entities: Entity[];
    title: string;
}

export function KPIGrid({
    entityCodes,
    selectedDataLevel,
    date,
    metrics,
    attributeDefinitions,
    entities,
    title,
}: KPIGridProps): ReactElement {
    const requests = uniqBy(metrics, (metric) => getKPIRequestKey(metric));
    const { data: kpis } = useGetKPIs({
        entityCodes,
        selected_data_level: selectedDataLevel,
        date: moment(date).format('YYYY-MM-DD'),
        kpi_requests: requests,
    });

    const columns = metrics.reduce<JSX.Element[]>((result, metric) => {
        const columnMappingGenerator = KPIColumnMappings[metric.kpi];

        if (!columnMappingGenerator) {
            return result;
        }

        const attributeDefinition = metric.attributeCode
            ? attributeDefinitions.find(
                  (attr) => attr.attribute_code === metric.attributeCode,
              )
            : undefined;

        const columnMappings = columnMappingGenerator(
            metric,
            attributeDefinition,
        );
        const columnMapping = columnMappings.find(
            (mapping) =>
                mapping.key === getKPIRequestKey(metric, metric.metric),
        );

        if (!columnMapping) {
            return result;
        }

        const dataType =
            mapAttributeType(attributeDefinition?.type, false) ?? 'number';
        const format =
            columnMapping.format ??
            mapAttributeFormat(
                attributeDefinition?.type,
                attributeDefinition?.format,
                attributeDefinition?.precision,
            );

        const column = (
            <Column
                format={format}
                caption={columnMapping.title}
                dataField={columnMapping.key}
                dataType={dataType}
                key={columnMapping.key}
                customizeText={({ value, valueText }) => {
                    if (Array.isArray(value)) {
                        return value.join(', ');
                    }

                    return valueText;
                }}
                width="150px"
            />
        );

        return [...result, column];
    }, []);

    const kpisByEntityCode = entityCodes.reduce<{
        [key: string]: { [key: string]: number | string | string[] };
    }>((result, entityCode) => {
        const resultsByColumn = kpis?.reduce<{
            [key: string]: number | string | string[];
        }>((results, kpiResponse) => {
            const columnMappingGenerator = KPIColumnMappings[kpiResponse.kpi];

            if (!columnMappingGenerator) {
                return results;
            }

            const attributeDefinition = getAttributeDefinitionFromKPIRequest(
                kpiResponse,
                attributeDefinitions,
            );
            const columnMappings = columnMappingGenerator(
                kpiResponse,
                attributeDefinition,
            );

            const dataForEntityCode = kpiResponse.results.find(
                ({ entityCode: ec }) => ec === entityCode,
            );

            if (!dataForEntityCode) {
                columnMappings.forEach(
                    ({ key, defaultValue }) =>
                        (results[key] = defaultValue ?? 0),
                );
                return results;
            }

            columnMappings.forEach(
                ({ getValue, key, defaultValue }) =>
                    (results[key] = dataForEntityCode.data.length
                        ? getValue(dataForEntityCode.data)
                        : defaultValue ?? 0),
            );

            return results;
        }, {});

        const entity = entities.find(
            (entity) => entity.entityCode === entityCode,
        );

        result[entityCode] = {
            ...(result[entityCode] ?? {
                entityCode,
                name: entity?.displayName ?? '--',
            }),
            ...resultsByColumn,
        };

        return result;
    }, {});

    const onExporting = useCallback(
        async (e: ExportingEvent) => {
            await exportExcelFromDevExtremeDataGrid(e, {
                worksheetName: `KPIs - ${title}`,
                filename: `KPIs - ${title}.xlsx`,
            });
        },
        [title],
    );

    return (
        <DataGrid
            className={dataGridStyles}
            height={'85vh'}
            dataSource={Object.values(kpisByEntityCode)}
            allowColumnResizing={true}
            columnResizingMode="widget"
            allowColumnReordering={false}
            hoverStateEnabled={true}
            showRowLines={true}
            showBorders={true}
            wordWrapEnabled={true}
            showColumnLines={true}
            onExporting={onExporting}
        >
            <Export enabled={true} allowExportSelectedData={false} />
            <Paging enabled={true} defaultPageSize={60} />
            <Scrolling mode="standard" showScrollbar="onScroll" />
            <Pager
                visible
                displayMode={'full'}
                showPageSizeSelector={true}
                allowedPageSizes={[20, 40, 60, 80, 100]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} items)"
            />
            <FilterPanel visible={true} />
            <FilterRow visible={true} />
            <Column
                caption="Property Name"
                dataField="name"
                minWidth={200}
                sortOrder="asc"
                fixed
                fixedPosition="left"
                allowHeaderFiltering
            />

            {columns}
        </DataGrid>
    );
}
