import { capitalize } from 'lodash';
import { currencyRenderer, integerRenderer } from 'utils/tables/renderers';
import {
    RentRollProps,
    RentRollPropsWithBuckets,
    SavedConfiguration,
} from 'waypoint-types';
import { Table } from 'antd';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    applySavedConfigSortingToGridData,
    applySavedFiltersToGridData,
    convertColumnsToAntd,
    getColumnTotalCells,
    getTableOrGroupSummaryData,
    groupHeaderRowClass,
    headerClass,
    rowClass,
    sortDataByColumnKey,
} from './GridExportConversionUtils';
import { DASH_DASH } from 'config/constants';
import { Dictionary } from 'ts-essentials';
import { safeDivision } from 'shared-types';
import { recurringChargeBuckets } from 'components/leases/expirations/cards/rent-roll/utils';
import { useMemo, useState } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

interface RentRollExportGridProps {
    rentRoll: RentRollPropsWithBuckets[];
    savedConfig: SavedConfiguration | null;
    tenantsEnabled: boolean;
    unitMixEnabled: boolean;
    selectedViewIsChargeGroup: boolean;
    sortSelection: string | undefined;
    sortOrderAscending: boolean;
}

export interface RentRollWithGroupHeaderFlag extends RentRollProps {
    isGroupHeader?: boolean;
    children?: RentRollWithGroupHeaderFlag[];
}

const exportableRentRollCustomSummaryDataFields = [
    'tenant_name',
    'lease_code',
    'leasable_space_code',
    'space_number',
    'rent_monthly',
    'source_system_lease_code',
];

const exportableRentRollCustomCalculationFields = [
    'cam_annual_per_sq_ft',
    'cam_monthly_per_sq_ft',
    'taxes_insurance_monthly_per_sq_ft',
    'taxes_insurance_annual_per_sq_ft',
    'other_monthly_per_sq_ft',
    'other_annual_per_sq_ft',
    'rent_monthly_per_sq_ft',
    'rent_annual_per_sq_ft',
    'total_monthly_per_sq_ft',
    'total_annual_per_sq_ft',
];

const dateFormatter = (dateString: string | null) => {
    if (!dateString) {
        return '';
    }
    return formatInTimeZone(new Date(dateString), 'UTC', 'M/d/yyyy');
};

export const rentRollBaseColumns = (
    tenantsEnabled: boolean,
    unitMixEnabled: boolean,
    selectedViewIsChargeGroup: boolean,
) => {
    const initialColumns = [
        {
            title: 'Property',
            dataIndex: 'property_name',
            dataType: 'string',
            key: 'property_name',
            width: '100px',
        },
        {
            title: 'Property Code',
            dataIndex: 'entity_display_code',
            dataType: 'string',
            key: 'entity_display_code',
            width: '75px',
        },
        {
            title: 'Unit Information',
            dataIndex: 'unit_information',
            key: 'unit_information',
            children: unitMixEnabled
                ? [
                      {
                          title: 'Unit #',
                          dataIndex: 'space_number',
                          dataType: 'string',
                          key: 'space_number',
                          align: 'center',
                          render: (
                              value: number | string,
                              rowData: RentRollWithGroupHeaderFlag,
                          ) => {
                              return rowData?.isGroupHeader &&
                                  typeof value === 'number'
                                  ? `${value} Units`
                                  : value;
                          },
                      },
                      {
                          title: 'Beds',
                          dataIndex: 'bedroom_count',
                          dataType: 'number',
                          align: 'center',
                          key: 'bedroom_count',
                      },
                      {
                          title: 'Rentable SF',
                          dataIndex: 'rentable_sq_ft',
                          dataType: 'number',
                          align: 'center',
                          key: 'rentable_sq_ft',
                          render: integerRenderer,
                      },
                      {
                          title: 'Status',
                          dataIndex: 'space_occupancy_status',
                          dataType: 'string',
                          align: 'center',
                          key: 'space_occupancy_status',
                      },
                  ]
                : [
                      {
                          title: 'Unit #',
                          dataIndex: 'space_number',
                          dataType: 'string',
                          align: 'center',
                          key: 'space_number',
                          render: (
                              value: number | string,
                              rowData: RentRollWithGroupHeaderFlag,
                          ) => {
                              return rowData?.isGroupHeader &&
                                  typeof value === 'number'
                                  ? `${value} Units`
                                  : value;
                          },
                      },
                      {
                          title: 'Rentable SF',
                          dataIndex: 'rentable_sq_ft',
                          dataType: 'number',
                          align: 'center',
                          key: 'rentable_sq_ft',
                          render: integerRenderer,
                      },
                      {
                          title: 'Status',
                          dataIndex: 'space_occupancy_status',
                          dataType: 'string',
                          align: 'center',
                          key: 'space_occupancy_status',
                      },
                  ],
        },
        {
            title: 'Lease Information',
            dataIndex: 'lease_information',
            key: 'lease_information',
            children: tenantsEnabled
                ? [
                      {
                          title: 'Lease Code',
                          dataType: 'string',
                          dataIndex: 'source_system_lease_code',
                          key: 'space_number',
                          align: 'center',
                          render: (
                              value: number | string,
                              rowData: RentRollWithGroupHeaderFlag,
                          ) => {
                              return rowData?.isGroupHeader &&
                                  typeof value === 'number'
                                  ? `${value} Leases`
                                  : value;
                          },
                      },
                      {
                          title: 'Tenant',
                          dataType: 'string',
                          dataIndex: 'tenant_name',
                          key: 'tenant_name',
                          width: '150px',
                          render: (
                              value: number | string,
                              rowData: RentRollWithGroupHeaderFlag,
                          ) => {
                              return rowData?.isGroupHeader &&
                                  typeof value === 'number'
                                  ? `${value} Tenants`
                                  : value;
                          },
                      },
                      {
                          title: 'Industry',
                          dataType: 'string',
                          dataIndex: 'tenant_industry',
                          key: 'tenant_industry',
                          render: (text?: string) => {
                              return (
                                  text
                                      ?.split(' ')
                                      .map((word) => capitalize(word))
                                      .join(' ') ?? ''
                              );
                          },
                      },
                      {
                          title: 'Type',
                          dataType: 'string',
                          dataIndex: 'lease_type',
                          key: 'lease_type',
                          align: 'center',
                      },
                  ]
                : [
                      {
                          title: 'Lease Code',
                          dataType: 'string',
                          dataIndex: 'source_system_lease_code',
                          key: 'space_number',
                          align: 'center',
                          render: (
                              value: number | string,
                              rowData: RentRollWithGroupHeaderFlag,
                          ) => {
                              return rowData?.isGroupHeader &&
                                  typeof value === 'number'
                                  ? `${value} Leases`
                                  : value;
                          },
                      },
                      {
                          title: 'Tenant',
                          dataType: 'string',
                          dataIndex: 'tenant_name',
                          key: 'tenant_name',
                          width: '150px',
                          render: (
                              value: number | string,
                              rowData: RentRollWithGroupHeaderFlag,
                          ) => {
                              return rowData?.isGroupHeader &&
                                  typeof value === 'number'
                                  ? `${value} Tenants`
                                  : value;
                          },
                      },
                      {
                          title: 'Type',
                          dataType: 'string',
                          dataIndex: 'lease_type',
                          key: 'lease_type',
                          align: 'center',
                      },
                  ],
        },
        {
            title: 'Term',
            dataIndex: 'term',
            key: 'term',
            children: [
                {
                    title: 'Start',
                    dataIndex: 'lease_start_date',
                    dataType: 'date',
                    key: 'lease_start_date',
                    align: 'center',
                    render: dateFormatter,
                },
                {
                    title: 'End',
                    dataIndex: 'lease_expiration_date',
                    dataType: 'date',
                    key: 'lease_expiration_date',
                    align: 'center',
                    render: dateFormatter,
                },
                {
                    title: 'Exp. Year',
                    dataIndex: 'expiration_year',
                    dataType: 'number',
                    key: 'expiration_year',
                    align: 'center',
                },
                {
                    title: 'Term Mos.',
                    dataIndex: 'lease_term_months',
                    dataType: 'number',
                    key: 'lease_term_months',
                    width: '50px',
                    align: 'center',
                    render: (
                        value: number,
                        rowData: RentRollWithGroupHeaderFlag,
                    ) => integerRenderer(value, rowData?.isGroupHeader ? 1 : 0),
                },
                {
                    title: 'Mos. Remaining',
                    dataIndex: 'remaining_months',
                    dataType: 'number',
                    key: 'remaining_months',
                    align: 'center',
                    render: (
                        value: number,
                        rowData: RentRollWithGroupHeaderFlag,
                    ) => integerRenderer(value, rowData?.isGroupHeader ? 1 : 0),
                },
            ],
        },
    ];

    const selectedChargeColumns = [
        {
            title: 'Rent',
            dataIndex: 'rent',
            key: 'rent',
            children: [
                {
                    title: 'Monthly',
                    dataIndex: 'rent_monthly',
                    dataType: 'number',
                    key: 'rent_monthly',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Monthly / SF',
                    dataIndex: 'rent_monthly_per_sq_ft',
                    dataType: 'number',
                    key: 'rent_monthly_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual',
                    dataIndex: 'rent_annual',
                    dataType: 'number',
                    key: 'rent_annual',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual / SF',
                    dataIndex: 'rent_annual_per_sq_ft',
                    dataType: 'number',
                    key: 'rent_annual_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
            ],
        },
        {
            title: 'CAM',
            dataIndex: 'cam',
            key: 'cam',
            children: [
                {
                    title: 'Monthly',
                    dataIndex: 'cam_monthly',
                    dataType: 'number',
                    key: 'cam_monthly',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Monthly / SF',
                    dataIndex: 'cam_monthly_per_sq_ft',
                    dataType: 'number',
                    key: 'cam_monthly_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual',
                    dataIndex: 'cam_annual',
                    dataType: 'number',
                    key: 'cam_annual',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual / SF',
                    dataIndex: 'cam_annual_per_sq_ft',
                    dataType: 'number',
                    key: 'cam_annual_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
            ],
        },
        {
            title: 'Taxes & Insurance',
            dataIndex: 'taxes_and_insurance',
            key: 'taxes_and_insurance',
            children: [
                {
                    title: 'Monthly',
                    dataIndex: 'taxes_insurance_monthly',
                    dataType: 'number',
                    key: 'taxes_insurance_monthly',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Monthly / SF',
                    dataIndex: 'taxes_insurance_monthly_per_sq_ft',
                    dataType: 'number',
                    key: 'taxes_insurance_monthly_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual',
                    dataIndex: 'taxes_insurance_annual',
                    dataType: 'number',
                    key: 'taxes_insurance_annual',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual / SF',
                    dataIndex: 'taxes_insurance_annual_per_sq_ft',
                    dataType: 'number',
                    key: 'taxes_insurance_annual_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
            ],
        },
        {
            title: 'Other',
            dataIndex: 'other',
            key: 'other',
            children: [
                {
                    title: 'Monthly',
                    dataIndex: 'other_monthly',
                    dataType: 'number',
                    key: 'other_monthly',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Monthly / SF',
                    dataIndex: 'other_monthly_per_sq_ft',
                    dataType: 'number',
                    key: 'other_monthly_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual',
                    dataIndex: 'other_annual',
                    dataType: 'number',
                    key: 'other_annual',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual / SF',
                    dataIndex: 'other_annual_per_sq_ft',
                    dataType: 'number',
                    key: 'other_annual_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
            ],
        },
    ];

    const totalChargeColumns = [
        {
            title: 'Total Charges',
            dataIndex: 'total_charges',
            key: 'total_charges',
            children: [
                {
                    title: 'Monthly',
                    dataIndex: 'total_monthly',
                    dataType: 'number',
                    key: 'total_monthly',
                    width: '125px',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Monthly / SF',
                    dataIndex: 'total_monthly_per_sq_ft',
                    dataType: 'number',
                    key: 'total_monthly_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual',
                    dataIndex: 'total_annual',
                    dataType: 'number',
                    key: 'total_annual',
                    width: '125px',
                    align: 'center',
                    render: currencyRenderer,
                },
                {
                    title: 'Annual / SF',
                    dataIndex: 'total_annual_per_sq_ft',
                    dataType: 'number',
                    key: 'total_annual_per_sq_ft',
                    align: 'center',
                    render: currencyRenderer,
                },
            ],
        },
    ];

    if (selectedViewIsChargeGroup) {
        return [
            ...initialColumns,
            ...selectedChargeColumns,
            ...totalChargeColumns,
        ];
    }

    return [...initialColumns, ...totalChargeColumns];
};

const rentRollTableSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> =
    {
        space_number: {
            summaryType: 'custom',
            render: (value: number) => {
                return `${value} Units`;
            },
        },
        tenant_name: {
            summaryType: 'custom',
            render: (value: number) => {
                return `${value} Tenants`;
            },
        },
        source_system_lease_code: {
            summaryType: 'custom',
            render: (value: number) => {
                return `${value} Leases`;
            },
        },
        rentable_sq_ft: {
            summaryType: 'custom',
            render: (value: number) => integerRenderer(value, 1),
        },
        lease_term_months: {
            summaryType: 'avg',
            render: (value: number) => integerRenderer(value, 1),
        },
        remaining_months: {
            summaryType: 'custom',
            render: (value: number) => integerRenderer(value, 1),
        },
        rent_monthly: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        rent_monthly_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        rent_annual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        rent_annual_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        cam_monthly: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        cam_monthly_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        cam_annual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        cam_annual_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        taxes_insurance_monthly: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        taxes_insurance_monthly_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        taxes_insurance_annual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        taxes_insurance_annual_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        other_monthly: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        other_monthly_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        other_annual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        other_annual_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        total_monthly: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        total_monthly_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        total_annual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        total_annual_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        expiration_year: {
            summaryType: '',
            render: () => '',
        },
    };

export const weightedAverageMosCalculation = (data: RentRollProps[]) => {
    const metricKey = 'remaining_months';
    const scaleKey = 'rentable_sq_ft';
    const filterKey = 'space_occupancy_status';
    const filterValue = 'VACANT';

    const totalRemainingMonths = data.reduce(
        (acc, rr) => {
            if (rr[filterKey] === filterValue) {
                return acc;
            }
            const scaleKeySum = isNaN(Number(rr[scaleKey]))
                ? 0
                : Number(rr[scaleKey]);

            acc.sum += scaleKeySum;

            if (rr[metricKey] === null || rr[scaleKey] === null) {
                return acc;
            }

            const scaleValue = Number(rr[scaleKey]) ?? 1;
            const metricValue = Number(rr[metricKey] ?? 0);
            acc.weighted += scaleValue * metricValue;
            return acc;
        },
        {
            sum: 0,
            weighted: 0,
        },
    );
    return (
        totalRemainingMonths.weighted / Math.max(totalRemainingMonths.sum, 1)
    );
};

const calculateCustomSummary = (
    field: string,
    data: RentRollPropsWithBuckets[],
) => {
    if (exportableRentRollCustomCalculationFields.includes(field)) {
        const reducerBase = {
            rentable_sq_ft: 0,
            [field]: 0,
        };

        const totalValues = data.reduce((dict, rr) => {
            dict.rentable_sq_ft += rr.rentable_sq_ft;
            const isABucketField = recurringChargeBuckets.some((bucket) =>
                field.includes(bucket),
            );
            if (isABucketField && field.includes('per_sq_ft')) {
                // get the annual or monthly field name
                const columnNamesMatch = /total_|group_|_per_sq_ft/g;
                const bucketAmountField = field.replace(
                    columnNamesMatch,
                    '',
                ) as keyof RentRollPropsWithBuckets;
                if (rr[bucketAmountField] !== undefined) {
                    dict[field] += Number(rr[bucketAmountField] ?? 0);
                }
            }
            if (!isABucketField) {
                dict[field] += field.includes('monthly_per_sq_ft')
                    ? rr.total_monthly ?? 0
                    : rr.total_annual ?? 0;
            }
            return dict;
        }, reducerBase);
        return safeDivision(
            Number(totalValues[field]),
            totalValues.rentable_sq_ft,
        );
    }

    const getAdjustedField = (field: keyof RentRollPropsWithBuckets) => {
        if (field === 'source_system_lease_code') {
            return 'lease_code';
        }
        if (field === 'space_number') {
            return 'leasable_space_code';
        }
        return field;
    };

    if (exportableRentRollCustomSummaryDataFields.includes(field)) {
        const uniqueValues = data.reduce((set, rr) => {
            const adjustedField = getAdjustedField(
                field as keyof RentRollPropsWithBuckets,
            );
            const value = rr[adjustedField as keyof RentRollPropsWithBuckets];
            if (value && !set.has(value)) {
                set.add(value);
            }
            return set;
        }, new Set());
        return uniqueValues.size;
    }
    if (field === 'rentable_sq_ft') {
        const reducerBase = {
            totalRSF: 0,
            uniqueLeasableSpaceCode: new Set<string>(),
        };

        const totalValues = data.reduce((dict, rr) => {
            if (!dict.uniqueLeasableSpaceCode.has(rr.leasable_space_code)) {
                dict.totalRSF += rr.rentable_sq_ft;
            }
            dict.uniqueLeasableSpaceCode.add(rr.leasable_space_code);
            return dict;
        }, reducerBase);

        return totalValues.totalRSF;
    }
    if (field === 'remaining_months') {
        return weightedAverageMosCalculation(data);
    }
    return 0;
};

const rentRollGroupTreeBuilder = (
    rentRoll: RentRollProps[],
    gridColumns: ColumnDescriptor[],
    groupingKeys: (keyof RentRollProps)[],
    blankFields: string[] = [],
    sortSelection: string | undefined,
    sortOrderAscending: boolean,
): RentRollWithGroupHeaderFlag[] => {
    if (!groupingKeys.length) {
        return rentRoll;
    }

    const groupingKey = groupingKeys[0];
    const groupedRentRoll = rentRoll.reduce(
        (dict, rr: RentRollProps) => {
            let rrField = rr[groupingKey] as string;
            if (rrField === null || rrField === undefined) {
                rrField = '';
            }
            if (!Object.keys(dict).includes(rrField)) {
                dict[rrField] = [rr];
                return dict;
            }
            dict[rrField].push(rr);
            return dict;
        },
        {} as Dictionary<RentRollProps[]>,
    );

    const groupedRentRollData: Partial<RentRollWithGroupHeaderFlag>[] = [];
    for (const entry of Object.entries(groupedRentRoll)) {
        const groupRow = {
            [groupingKey]: entry[0],
            isGroupHeader: true,
        };

        const groupTotals = getTableOrGroupSummaryData(
            entry[1] as RentRollPropsWithBuckets[],
            gridColumns,
            rentRollTableSummaryFormatters,
            calculateCustomSummary,
        );

        const innerRowBlankFields = blankFields.reduce((dict, bf) => {
            dict[bf] = '';
            return dict;
        }, {} as Dictionary<string>);

        groupedRentRollData.push({
            ...groupTotals,
            ...innerRowBlankFields,
            ...groupRow,
            children:
                groupingKeys.length > 1
                    ? rentRollGroupTreeBuilder(
                          entry[1],
                          gridColumns,
                          groupingKeys.slice(1),
                          groupingKeys,
                          sortSelection,
                          sortOrderAscending,
                      )
                    : entry[1].map((rr: RentRollProps) => {
                          return {
                              ...rr,
                              ...innerRowBlankFields,
                          };
                      }),
        });
    }

    const sortingKey = (sortSelection ??
        groupingKey) as keyof RentRollWithGroupHeaderFlag;
    const dataType =
        gridColumns.find((gc) => gc.dataIndex === sortingKey)?.dataType ??
        'string';
    const sortOrder = !sortOrderAscending ? 'desc' : 'asc';
    return sortDataByColumnKey(
        dataType,
        sortingKey,
        sortOrder,
        groupedRentRollData as RentRollWithGroupHeaderFlag[],
    ) as RentRollWithGroupHeaderFlag[];
};

const customizeTenantNameAndIndustryCellValue = (rr: RentRollProps) => {
    if (!rr.tenant_name && rr.space_occupancy_status === 'OCCUPIED') {
        rr.tenant_name = ' ';
    }
    if (rr.tenant_industry === null || rr.tenant_industry === undefined) {
        rr.tenant_industry = DASH_DASH;
    }
    return rr;
};

export const RentRollExportableGrid = ({
    rentRoll,
    savedConfig,
    tenantsEnabled,
    unitMixEnabled,
    selectedViewIsChargeGroup,
    sortSelection,
    sortOrderAscending,
}: RentRollExportGridProps): JSX.Element => {
    const [filteredRentRoll, setFilteredRentRoll] = useState<
        RentRollProps[] | null
    >(null);

    // get the base column structure for antd and apply any relevant saved config settings
    const {
        gridColumns: gridColumnsRawWithHeaders,
        groupedColumnNames,
        columnsForFilteringAndSorting,
    } = convertColumnsToAntd(
        rentRollBaseColumns(
            tenantsEnabled,
            unitMixEnabled,
            selectedViewIsChargeGroup,
        ) as ColumnDescriptor[],
        true,
        savedConfig,
    );

    // columns hidden from report widgets by default
    // these should only be included if they're used in grouping
    const hiddenColumns = ['property_name', 'entity_display_code'].filter(
        (hc) => !groupedColumnNames.includes(hc),
    );

    const gridColumnsWithHeaders = gridColumnsRawWithHeaders.filter(
        (gc) => !hiddenColumns.includes(gc.dataIndex),
    );

    const gridColumns = gridColumnsWithHeaders.flatMap((col) => {
        if (col.children && col.children.length) {
            return col.children;
        }
        return col;
    });

    // apply saved config filters to data
    useMemo(async () => {
        // apply some customization rules to rent roll data as we do for the devextreme rr grid
        const rentRollFormattedData = rentRoll.map((rr) =>
            customizeTenantNameAndIndustryCellValue(rr),
        );
        if (!savedConfig?.filters_json?.grid_config?.filterValue) {
            setFilteredRentRoll(rentRollFormattedData);
            return;
        }
        const filteredData = await applySavedFiltersToGridData(
            rentRollFormattedData,
            savedConfig,
            columnsForFilteringAndSorting,
        );
        setFilteredRentRoll(filteredData);
    }, [rentRoll]);

    if (!filteredRentRoll) {
        return <></>;
    }

    // apply saved config sorting to data
    const sortedRentRoll = applySavedConfigSortingToGridData(
        filteredRentRoll,
        columnsForFilteringAndSorting,
        savedConfig,
    );

    // apply saved config grouping to data
    // P.S. yes, this looks weird passing groupedColumnNames twice,
    // but it's needed bc of the recursion in this function
    const processedGridData = rentRollGroupTreeBuilder(
        sortedRentRoll as RentRollProps[],
        columnsForFilteringAndSorting,
        groupedColumnNames as (keyof RentRollProps)[],
        groupedColumnNames,
        sortSelection,
        sortOrderAscending,
    );

    return (
        <Table
            dataSource={processedGridData as RentRollWithGroupHeaderFlag[]}
            size="small"
            columns={gridColumnsWithHeaders}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={(row) => {
                return row?.isGroupHeader ? groupHeaderRowClass : rowClass;
            }}
            expandable={{
                defaultExpandAllRows:
                    savedConfig?.filters_json?.local_config?.expanded ?? false,
                expandIcon: () => {
                    return null;
                },
            }}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    sortedRentRoll as RentRollPropsWithBuckets[],
                    rentRollTableSummaryFormatters,
                    calculateCustomSummary,
                )
            }
        />
    );
};
