import React, { useEffect, useState } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import { clientLoaded } from 'state/user/reducer';
import { hasReportTemplateEnabled } from 'state/user/selectors';
import { css } from 'emotion';
import { RouteUrls } from 'routes/RouteConstants';
import { usePermissions } from 'contexts';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import theme from 'config/theme';
import { roles as userRoles } from 'config/constants';
import { Dictionary } from 'ts-essentials';
import { MenuSections, menuItemStyle } from '../AppLayoutUtils';

const logoStyle = css`
    display: flex;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 12px;
`;

const overrideMenuItemStyle = css`
    .ant-menu-item-selected {
        background-color: unset;
        color: unset;
    }
    color: ${theme.colors.white};
`;

interface NavigationProps {
    collapsed: boolean;
    showDrawer: (menuKey: string) => void;
    location: { pathname: string };
    isLoading: boolean;
    isAdmin: boolean;
    hasReportTemplates: boolean;
}

const Navigation: React.FC<NavigationProps> = ({
    collapsed,
    showDrawer,
    location,
    isLoading,
    isAdmin,
    hasReportTemplates,
}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showFinancialsMenu, setShowFinancialsMenu] = useState<boolean>(true);
    const [showAnalyticsMenu, setShowAnalyticsMenu] = useState<boolean>(true);
    const [showLeasingMenu, setShowLeasingMenu] = useState<boolean>(true);
    const [showPlanningMenu, setShowPlanningMenu] = useState<boolean>(true);
    const [showWorkflowMenu, setShowWorkflowMenu] = useState<boolean>(true);

    const {
        financialMenuSettings,
        analyticsMenuSettings,
        leasingMenuSettings,
        planningMenuSettings,
        workflowMenuSettings,
    } = usePermissions();

    const onClose = () => {
        setDrawerOpen(false);
    };

    useEffect(() => {
        drawerOpen && onClose();
    }, [location]);

    const showMenu = (menu: Dictionary<boolean>) => {
        return Object.values(menu).some((menuItem) => menuItem);
    };

    useEffect(() => {
        setShowFinancialsMenu(showMenu(financialMenuSettings));
        setShowAnalyticsMenu(showMenu(analyticsMenuSettings));
        setShowLeasingMenu(showMenu(leasingMenuSettings));
        setShowPlanningMenu(showMenu(planningMenuSettings));
        setShowWorkflowMenu(showMenu(workflowMenuSettings));
    }, [
        financialMenuSettings,
        analyticsMenuSettings,
        leasingMenuSettings,
        planningMenuSettings,
        workflowMenuSettings,
    ]);

    if (isLoading) {
        return <div style={{ width: 'auto' }} />;
    }

    const imageStyle = {
        height: '18px',
        maxHeight: '18px',
        color: 'white',
    };

    const showDashboardsMenu =
        showAnalyticsMenu || showLeasingMenu || showPlanningMenu;

    return (
        <Menu mode="inline" theme="dark" className={overrideMenuItemStyle}>
            <div className={logoStyle}>
                <img
                    style={{ height: 38, marginLeft: '-5px' }}
                    src={
                        collapsed
                            ? './assets/img/wp-logo-no-text.png'
                            : './assets/img/logo_white_text.png'
                    }
                    alt="waypoint-logo"
                />
            </div>
            <Menu.Item
                key="0"
                title={MenuSections.Home}
                icon={
                    <i
                        style={{
                            ...imageStyle,
                            marginRight: '9px',
                            fontSize: '16px',
                        }}
                        className="fa-solid fa-home fa-lg"
                    />
                }
                style={menuItemStyle(
                    location.pathname,
                    [RouteUrls.SEARCH],
                    false,
                )}
            >
                <Link to={RouteUrls.SEARCH}>Home</Link>
            </Menu.Item>
            {showDashboardsMenu && (
                <Menu.Item
                    key="1"
                    onClick={() => showDrawer(MenuSections.Dashboards)}
                    title={MenuSections.Dashboards}
                    icon={
                        <i
                            style={{
                                ...imageStyle,
                                marginRight: '9px',
                                fontSize: '16px',
                            }}
                            className="fa-solid fa-chart-line fa-lg"
                        />
                    }
                    style={menuItemStyle(
                        location.pathname,
                        [
                            RouteUrls.AGED_RECEIVABLES,
                            RouteUrls.ANALYTICS,
                            RouteUrls.ATTRIBUTES,
                            RouteUrls.LEASES,
                            RouteUrls.PLANNING,
                        ],
                        false,
                    )}
                >
                    {MenuSections.Dashboards}
                </Menu.Item>
            )}
            {showFinancialsMenu && (
                <Menu.Item
                    key="2"
                    onClick={() => showDrawer(MenuSections.Financials)}
                    title={MenuSections.Financials}
                    icon={
                        <i
                            style={{
                                ...imageStyle,
                                marginRight: '9px',
                                fontSize: '16px',
                            }}
                            className="fa-solid fa-circle-dollar-to-slot fa-lg"
                        />
                    }
                    style={menuItemStyle(
                        location.pathname,
                        [RouteUrls.FINANCIALS],
                        false,
                    )}
                >
                    {MenuSections.Financials}
                </Menu.Item>
            )}
            {hasReportTemplates && (isAdmin || showWorkflowMenu) && (
                <Menu.Item
                    key="6"
                    onClick={() => showDrawer(MenuSections.Workflows)}
                    title={MenuSections.Workflows}
                    icon={
                        <i
                            style={{
                                ...imageStyle,
                                marginRight: '9px',
                                fontSize: '16px',
                            }}
                            className="fa-solid fa-list-check fa-lg"
                        />
                    }
                    style={menuItemStyle(
                        location.pathname,
                        [RouteUrls.REPORT],
                        false,
                    )}
                >
                    {MenuSections.Workflows}
                </Menu.Item>
            )}
        </Menu>
    );
};

const mapState = (state: RootStateOrAny) => {
    const {
        routing: { locationBeforeTransitions: location },
        user: { clientObj },
    } = state;

    const { roles } = state.user;
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    return {
        location,
        isAdmin,
        isLoading: !clientLoaded(clientObj),
        hasReportTemplates: hasReportTemplateEnabled(state),
    };
};
export default connect(mapState)(Navigation);
