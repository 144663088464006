export const DEFAULT_PERIOD_RANGE = 'trailing_12';

// NOTE (Daniel): Top level settings menu items
export const PERSONAL_PROFILE_URL_SEGMENT = 'personal';
export const USER_MANAGEMENT_URL_SEGMENT = 'users';
export const CLIENT_SETTINGS_URL_SEGMENT = 'client';
export const PERMISSIONS_URL_SEGMENT = 'permission-groups';
export const COMPANY_URL_SEGMENT = 'company';
export const ADMIN_SETTINGS_URL_SEGMENT = '/settings/admin';
export const USER_PROFILE_SETTINGS_SEGMENT = '/settings/user/personal';
// NOTE (Daniel): Several status strings
export const STATUS_ACTIVE = 'active';

// NOTE (Daniel): User Roles
export const roles = {
    CLIENT_ADMIN: 'ClientAdmin',
    CLIENT_USER: 'ClientUser',
    WAYPOINT_SYSADMIN: 'WaypointSystemAdministrator',
    WAYPOINT_ASSOCIATE: 'WaypointAssociate',
};

export const PROPERTY_CACHE_KEY = 'PropertySlim_';

export const PROPERTY_GROUP_CACHE_KEY = 'PropertyGroup_';

/**
 * FEATURE FLAGS
 */
export const FEATURE_NATIVE_ANALYTICS = 'FEATURE_NATIVE_ANALYTICS';
export const FEATURE_PORTFOLIO_SEGMENTATION = 'FEATURE_PORTFOLIO_SEGMENTATION';
export const FEATURE_BALANCE_SHEET = 'FEATURE_BALANCE_SHEET';
export const FEATURE_BALANCE_SHEET_OVERVIEW = 'FEATURE_BALANCE_SHEET_OVERVIEW';
export const FEATURE_OPPORTUNITIES = 'FEATURE_OPPORTUNITIES';
export const LEASES_FEATURE_FLAG = 'FEATURE_LEASES';
export const FEATURE_PROPERTY_ATTRIBUTES = 'FEATURE_PROPERTY_ATTRIBUTES';
export const FEATURE_TENANTS = 'FEATURE_TENANTS';
export const FEATURE_CROSSTAB = 'FEATURE_CROSSTAB';
export const FEATURE_OCCUPANCY_TREND = 'FEATURE_OCCUPANCY_TREND';
export const FEATURE_VARIANCE_REPORT_DASHBOARD =
    'FEATURE_VARIANCE_REPORT_DASHBOARD';
export const FEATURE_CAPITAL_TRACKER = 'FEATURE_CAPITAL_TRACKER';
export const FEATURE_REPORT_TEMPLATE = 'FEATURE_REPORT_TEMPLATE';
export const FEATURE_PLANNING_SECTION = 'FEATURE_PLANNING_SECTION';
export const FEATURE_EXPIRATION_SCHEDULE = 'FEATURE_EXPIRATION_SCHEDULE';
export const FEATURE_RENT_ROLL = 'FEATURE_RENT_ROLL';
export const FEATURE_UNIT_MIX = 'FEATURE_UNIT_MIX';
export const FEATURE_AGED_RECEIVABLES = 'FEATURE_AGED_RECEIVABLES';
export const FEATURE_BUDGET_UPLOAD = 'FEATURE_BUDGET_UPLOAD';
export const FEATURE_VALUE_AND_DEBT = 'FEATURE_VALUE_AND_DEBT';
export const FEATURE_OWNERSHIP_PERCENTAGE = 'FEATURE_OWNERSHIP_PERCENTAGE';
export const FEATURE_DOCUMENTS = 'FEATURE_DOCUMENTS';
export const FEATURE_KPIS = 'FEATURE_KPIS';

export const DASH_DASH = '––';
export const API_URL = process.env.NX_PUBLIC_API_URL;
