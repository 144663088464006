import { API_URL } from 'config/constants';
import { SavedConfiguration, SavedConfigurationObject } from 'waypoint-types';
import { KPIMetric } from 'shared-types';

interface UpdateSavedConfigurationParams {
    id: string;
    name: string;
    filters_json: SavedConfigurationObject | KPIMetric[];
    reference_type: string;
}

const updateSavedConfiguration = async (
    updatedConfig: UpdateSavedConfigurationParams,
): Promise<SavedConfiguration> => {
    const response = await fetch(
        `${API_URL}/global-filters/${updatedConfig.id}`,
        {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: updatedConfig.id,
                name: updatedConfig.name,
                filters_json: updatedConfig.filters_json,
                reference_type: updatedConfig.reference_type,
            } as UpdateSavedConfigurationParams),
        },
    );

    if (!response.ok) {
        throw new Error('Failed to update saved configuration');
    }

    const { data } = await response.json();

    return data as SavedConfiguration;
};

export default updateSavedConfiguration;
