/**
 * Here's the list of all pages that have titles
 *
 * Need to add a new page?
 *  - add to this list with a js style regex pattern, a name & any other details you'd like to use
 *  - a match between the url & the regex will be found from top to bottom
 *      - specific above broad
 *  - if a url is changed then change the regex & the test url
 *  - here's a breakdown of each entry:
 *    {
 *      'name': name of the page,
 *      'regex': pattern to match using js syntax,
 *      'showEntityName': true or false depending if you want to show the entity name in the page title,
 *      'test': a url to test against
 *    }
 *  - testing done in client/__tests__/utils/pageTitle.spec.js
 */
export const pageDetailsList = [
    {
        name: 'Search Results',
        regex: 'search',
        showEntityType: true,
        showEntityName: false,
        test: '/search/properties',
    },
    {
        name: 'My Profile',
        regex: 'settings/user/personal',
        showEntityName: false,
        test: '/settings/user/personal',
    },
    {
        name: 'My Profile - Notifications',
        regex: 'settings/user/notifications',
        showEntityName: false,
        test: '/settings/user/notifications',
    },
    {
        name: 'Admin Settings - User Management',
        regex: 'settings/admin/users',
        showEntityName: false,
        test: '/settings/admin/users',
    },
    {
        name: 'Admin Settings - Email Notifications',
        regex: 'settings/admin/notifications',
        showEntityName: false,
        test: '/settings/admin/notifications',
    },
    {
        name: 'Admin Settings - Client Settings',
        regex: 'settings/admin/client',
        showEntityName: false,
        test: '/settings/admin/client',
    },
    {
        name: 'Admin Settings - Permissions Groups',
        regex: 'settings/admin/permission-groups',
        showEntityName: false,
        test: '/settings/admin/permission-groups',
    },
    {
        name: 'Admin Settings - Company',
        regex: 'settings/admin/company',
        showEntityName: false,
        test: '/settings/admin/company',
    },
    {
        name: 'Admin Settings - New permission group',
        regex: 'settings/admin/new-permission-group',
        showEntityName: false,
        test: '/settings/admin/new-permission-group',
    },
    {
        name: 'Admin Settings - Edit permission group',
        regex: 'settings/admin/edit-permission-group/d*',
        showEntityName: false,
        test: '/settings/admin/edit-permission-group/50d66f9b-77e4-4dc0-bf15-403456fff3e8',
    },
    {
        name: 'Property Profile',
        regex: 'property/profile',
        test: '/property/profile?pureid=1234',
    },
    {
        name: 'Task Profile',
        alternate: 'Task Profile',
        // TODO (Nicholas): Update URLs and links if Opportunities becomes Tasks
        regex: 'opportunity/d*',
        test: '/property/opportunity/4321?pureid=1234',
    },
    {
        name: 'Tasks',
        regex: 'opportunity',
        test: '/property/opportunity?pureid=1234',
    },
    {
        name: 'Financial Overview',
        regex: 'analytics/financial-overview',
        test: '/analytics/financial-overview',
    },
    {
        name: 'Ranking',
        regex: 'analytics/ranking',
        test: '/analytics/ranking',
    },
    {
        name: 'Portfolio Summary',
        regex: 'portfolio-summary',
        test: '/portfolio-summary',
    },
    {
        name: 'Leasing Performance Overview',
        regex: 'leases/performance-overview',
        test: '/leases/performance-overview',
    },
    {
        name: 'Tenants',
        regex: 'leases/tenants',
        test: '/leases/tenants',
    },

    {
        name: 'Balance Sheet',
        regex: 'financials/balance-sheet',
        test: '/financials/balance-sheet',
    },

    {
        name: 'Crosstab',
        regex: 'financials/crosstab',
        test: '/financials/crosstab',
    },

    {
        name: 'Income Statement',
        regex: 'financials/comparative-income-statement',
        test: '/financials/comparative-income-statement',
    },
    {
        name: 'Variance Report Status',
        regex: 'financials/variance-report-status',
        test: '/financials/variance-report-status',
    },
    {
        name: 'Variance Reporting',
        regex: 'financials/variance-reporting',
        test: '/financials/variance-reporting',
    },

    {
        name: 'Balance Sheet Overview',
        regex: 'analytics/balance-sheet-overview',
        test: '/analytics/balance-sheet-overview',
    },

    {
        name: 'Attributes',
        regex: 'attributes',
        test: '/attributes',
    },
    {
        name: 'Expiration Schedule',
        regex: 'leases/expiration-schedule',
        test: '/leases/expiration-schedule',
    },
    {
        name: 'Rent Roll',
        regex: 'leases/rent-roll',
        test: '/leases/rent-roll',
    },
    {
        name: 'Page Not Found',
        regex: 'error/404',
        test: 'error/404',
    },
    {
        name: 'Planning Hold/Sell',
        regex: 'planning/hold-sell',
        test: 'planning/hold-sell',
    },
    {
        name: 'Planning Capital Projects',
        regex: 'planning/capital-projects',
        test: 'planning/capital-projects',
    },
    {
        name: 'Priorities & Objectives',
        regex: '/planning/priorities-and-objectives',
        test: '/planning/priorities-and-objectives',
    },
    {
        name: 'Service Contracts',
        regex: '/planning/service-contracts',
        test: '/planning/service-contracts',
    },
    {
        name: 'Reports',
        regex: 'reports',
        test: 'reports',
    },
    {
        name: 'Aged Receivables',
        regex: 'aged-receivables',
        test: 'aged-receivables',
    },
    {
        name: 'Budget Upload',
        regex: 'budget-upload',
        test: 'budget-upload',
    },
    {
        name: 'Create Report',
        regex: 'report/create',
        test: 'report/create',
    },
    {
        name: 'Report Detail - Edit',
        regex: 'report/d*',
        test: 'report/50d66f9b-77e4-4dc0-bf15-403456fff3e8',
    },
    {
        name: 'Top Tenants',
        regex: 'leases/top-tenants',
        test: '/leases/top-tenants',
    },
    {
        name: 'KPIs',
        regex: 'kpis/*',
        test: '/kpis',
    },
];
