import React, {
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import theme from 'config/theme';
import DataGrid, {
    Export,
    HeaderFilter,
    Column,
    Summary,
    Format,
    TotalItem,
    ColumnChooser,
    DataGridRef,
    ColumnChooserSelection,
    Position,
    GroupPanel,
    Grouping,
    Toolbar,
    Item,
    SearchPanel,
    Paging,
    Scrolling,
    Pager,
    GroupItem,
    FilterRow,
    FilterPanel,
    StateStoring,
    ColumnFixing,
} from 'devextreme-react/data-grid';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import {
    useDataGridHeight,
    useUnitMix,
    useGetSavedConfigurationById,
} from 'waypoint-hooks';
import { filterRecurringCharges } from 'components/leases/components/recurring-charge/utils';
import {
    ExportingEvent,
    InitializedEvent,
    RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import {
    CellInfoType,
    RecurringCharge,
    SavedConfiguration,
    SavedConfigurationState,
    SelectedDataLevel,
} from 'waypoint-types';
import { onContentReady } from 'waypoint-utils';
import exportExcelFromDevExtremeDataGrid from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import { UnitMixExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/UnitMixExportExportableGrid';
import { AttributeFromAPI, EntityAttributesContext } from 'contexts';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { css } from 'emotion';
import { ExpandAndCollapseButton } from 'waypoint-react/index';
import {
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';
import { getUnitMixForGrid } from './utils';
import { DASH_DASH } from 'config/constants';

interface UnitMixTableProps {
    hidePropertyColumns?: boolean;
    widgetId?: string;
    widgetType?: string;
    widgetSettings?: { [key: string]: any };
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
    narrativePosition?: string;
    selectedChargeCode: string[];
    isReportWidget?: boolean;
    attributeSelection?: AttributeFromAPI | null;
    groupingSelection?: EntityDataGroupingKeys | null;
    selectedConfiguration?: SavedConfiguration | null;
    setGridConfig?: (config: { [x: string]: any } | null) => void;
    setLocalConfig?: (config: { [x: string]: any } | null) => void;
}

const detailSizeStyles = css`
    .dx-master-detail-cell div div {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
`;

const capitalizedCellValue = css`
    text-transform: capitalize;
`;

const UnitMixTable = React.forwardRef<PDFExportable, UnitMixTableProps>(
    (
        {
            widgetId,
            widgetType,
            widgetSettings,
            entityCodes,
            selectedDataLevel,
            hidePropertyColumns,
            attributeSelection,
            groupingSelection,
            selectedChargeCode,
            isReportWidget,
            isPDFExport,
            selectedConfiguration,
            setGridConfig,
            setLocalConfig,
        }: UnitMixTableProps,
        ref,
    ) => {
        const dataGridRef = useRef<DataGridRef<any, any>>(null);
        const [expanded, setExpanded] = useState<boolean>(false);
        const [expandButtonEnable, setExpandButtonEnable] =
            useState<boolean>(true);
        const [isReadyForPDFExport, setIsReadyForPDFExport] =
            useState<boolean>(false);

        const {
            calculateCustomSummary,
            recurringChargesData,
            unitMixData,
            isLoading,
        } = useUnitMix({
            entityCodes,
            selectedDataLevel,
        });

        const { data: savedConfigData } = useGetSavedConfigurationById(
            widgetSettings?.savedConfigId ?? '',
        );

        const dataGridHeight = useDataGridHeight({ topBarHeight: 160 });

        const toggleExpanded = () => {
            setExpanded(!expanded);
        };

        const onRowPrepared = (e: RowPreparedEvent) => {
            const isGrouped = e.rowType === 'group';
            const isGroupAndExpanded = e.rowType === 'group' && e.isExpanded;
            const isHeader = e.rowType === 'header';

            if (isGrouped) {
                e.rowElement.style.backgroundColor = theme.colors.white;
                // Add specific styling for when group is expanded
                if (isGroupAndExpanded) {
                    e.rowElement.style.backgroundColor =
                        theme.colors.grays.background;
                    e.rowElement.style.fontWeight = 'bolder';
                }
            }
            if (isHeader) {
                e.rowElement.style.fontWeight = 'bold';
                e.rowElement.style.textDecorationColor =
                    theme.colors.grays.text;
                e.rowElement.style.color = theme.colors.grays.text;
            }
        };

        const groupCell = (options: CellInfoType) => {
            return <div className={capitalizedCellValue}>{options.value}</div>;
        };

        const groupCellBedrooms = (options: CellInfoType) => {
            const cellValue = options.value;
            const displayValue =
                cellValue === 0 ? 'Studio' : `${cellValue} Bed`;

            return (
                <div className={`group-cell-${cellValue}`}>{displayValue}</div>
            );
        };

        const isAttributeGroupingSelection =
            groupingSelection === EntityDataGroupingKeys.Attributes;

        const entityAttributesContext = useContext(EntityAttributesContext);
        const attributeDefinitions =
            entityAttributesContext?.data?.attributeDefinitions;

        useEffect(() => {
            const config = widgetSettings
                ? savedConfigData?.filters_json?.local_config
                : selectedConfiguration?.filters_json?.local_config;
            if (config) {
                config['expanded'] !== undefined &&
                    setExpanded(config['expanded']);
            }

            if (setLocalConfig) {
                setLocalConfig({
                    expanded,
                    selectedChargeCode,
                    groupingSelection,
                    attributeSelection,
                });
            }
        }, [expanded, selectedChargeCode, savedConfigData, widgetSettings]);

        useMemo(() => {
            const groupIndex =
                groupingSelection === EntityDataGroupingKeys.Attributes &&
                dataGridRef
                    ? 0
                    : -1;
            dataGridRef?.current
                ?.instance()
                .columnOption(0, 'groupIndex', groupIndex);
        }, [dataGridRef, groupingSelection, attributeSelection]);

        const { recurringCharges } = recurringChargesData ?? {
            recurringCharges: [],
        };

        const filteredCharges = filterRecurringCharges(
            recurringCharges,
            selectedChargeCode,
        ) as RecurringCharge[];

        const transformedData = useMemo(() => {
            return getUnitMixForGrid(unitMixData ?? [], filteredCharges);
        }, [unitMixData, filteredCharges]);

        const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading && isReadyForPDFExport;
            },
        }));

        const onExporting = async (e: ExportingEvent) => {
            await exportExcelFromDevExtremeDataGrid(e, {
                worksheetName: 'Unit Mix',
                filename: 'unitmix.xlsx',
            });
        };

        if (isPDFExport) {
            return (
                <div style={{ marginTop: 24 }}>
                    <UnitMixExportableGrid
                        savedConfig={savedConfigData ?? null}
                        unitMix={transformedData}
                        setIsReadyForPDFExport={setIsReadyForPDFExport}
                    />
                </div>
            );
        }

        const saveState = (state: SavedConfigurationState) => {
            const config = createSavedConfigurationPayload(state);
            if (config && setGridConfig) {
                setGridConfig(config);
            }
        };

        const selectedAttribute =
            attributeSelection &&
            attributeDefinitions?.find(
                (attr) => attr.attribute_code === attributeSelection.key,
            );

        const groupedData =
            selectedAttribute &&
            groupingSelection === EntityDataGroupingKeys.Attributes
                ? transformedData?.map((d) => {
                      const values = selectedAttribute.attributeValues?.filter(
                          (attr) => attr.entity_code === d.entity_code,
                      );

                      const valueKey = values?.length
                          ? values.map((val) => val.value).join(', ')
                          : 'Unassigned';
                      return {
                          ...d,
                          [attributeSelection.key]: valueKey
                              ? (valueKey ?? 'Unassigned')
                              : 'Unassigned',
                      };
                  })
                : transformedData;

        return (
            <div id={widgetExportId} className={detailSizeStyles}>
                <DataGrid
                    style={{ height: 'auto' }}
                    dataSource={groupedData}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    allowColumnReordering={true}
                    wordWrapEnabled={false}
                    noDataText="No Unit Mix Data Available"
                    id="unit-mix-table"
                    ref={dataGridRef}
                    height={dataGridHeight}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    onExporting={onExporting}
                    onRowPrepared={onRowPrepared}
                    onContentReady={(e) => {
                        onContentReady({
                            e,
                            toggleFunc: setExpandButtonEnable,
                        });
                        if (savedConfigData && e?.component) {
                            applyStoredConfiguration(
                                e.component,
                                savedConfigData,
                            );
                        }
                    }}
                    onInitialized={(e: InitializedEvent) => {
                        if (selectedConfiguration && e?.component) {
                            applyStoredConfiguration(
                                e.component,
                                selectedConfiguration,
                            );
                        }
                    }}
                >
                    <Column
                        visible={isAttributeGroupingSelection}
                        showInColumnChooser={isAttributeGroupingSelection}
                        minWidth={180}
                        dataField={attributeSelection?.key}
                        name={attributeSelection?.key}
                        caption={attributeSelection?.title ?? ''}
                        width={'auto'}
                        allowHiding={false}
                        showWhenGrouped={true}
                        groupCellRender={groupCell}
                        fixed
                        fixedPosition="left"
                    />

                    <Column
                        dataField="property_name"
                        caption="Property"
                        allowFiltering={true}
                        showWhenGrouped={true}
                        alignment={'left'}
                        visible={!hidePropertyColumns}
                        sortOrder={'asc'}
                        groupCellRender={groupCell}
                        fixed
                        fixedPosition="left"
                    />

                    <Column
                        dataField="bedroom_count"
                        caption="Beds"
                        showWhenGrouped={true}
                        dataType={'number'}
                        alignment={'left'}
                        minWidth={100}
                        allowFiltering={true}
                        cellRender={(cellInfo: CellInfoType) => {
                            if (cellInfo.value === null) {
                                return DASH_DASH;
                            }
                            if (cellInfo.value === 0) {
                                return 'Studio';
                            }
                            return `${cellInfo.value} Bed`;
                        }}
                        groupCellRender={groupCellBedrooms}
                        sortOrder={'asc'}
                        fixed
                        fixedPosition="left"
                    />
                    <Column
                        dataField="unit_type"
                        caption="Unit Type"
                        alignment={'left'}
                        allowFiltering={true}
                        width={'auto'}
                        showWhenGrouped={true}
                        cellRender={(cellData) => {
                            return cellData.value ? cellData.value : '--';
                        }}
                        groupCellRender={groupCell}
                        fixed
                        fixedPosition="left"
                    />
                    <Column
                        caption="fake"
                        showInColumnChooser={false}
                        allowExporting={false}
                        fixed
                        fixedPosition="left"
                        width={0.01}
                    />
                    <Column
                        dataField="avg_sf"
                        caption="Avg SF"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="total_sf"
                        caption="Total SF"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="occupied_units"
                        caption="Total Occupied"
                        dataType="number"
                        alignment={'center'}
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="vacant_units"
                        caption="Total Vacant"
                        dataType="number"
                        alignment={'center'}
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="number_of_units"
                        caption="Total Units"
                        dataType="number"
                        alignment={'center'}
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="occupancy_percentage"
                        caption="Occ % (By Unit)"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="percent" precision={1} />
                    </Column>
                    <Column
                        dataField="percent_of_total_units"
                        caption="% of Total (By Unit)"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="percent" precision={1} />
                    </Column>
                    <Column
                        dataField="non_revenue_units"
                        caption="Non-Rev Units"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="occupied_notice"
                        caption="Occ. Notice"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="notice_available"
                        caption="Notice Avail."
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="vacant_unrented"
                        caption="Vacant Avail."
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="available_to_rent"
                        caption="ATR"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="atr_by_unit"
                        caption="ATR %"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        format={{ type: 'percent', precision: 1 }}
                    />
                    <Column
                        dataField="occupied_no_notice"
                        caption="Occ. No Notice"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="notice_rented"
                        caption="Notice Rented"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="vacant_rented"
                        caption="Vacant Rented"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="leased_units"
                        caption="Leased"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="leased_percentage"
                        caption="Leased %"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        format={{ type: 'percent', precision: 1 }}
                    />
                    <Column
                        dataField="average_total_monthly"
                        caption="Avg Monthly Charges"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="currency" precision={2} currency="USD" />
                    </Column>
                    <Column
                        dataField="average_total_monthly_psf"
                        caption="Avg Charge PSF"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="currency" precision={2} currency="USD" />
                    </Column>
                    <Column
                        dataField="total_monthly"
                        caption="Monthly Charge Total"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="currency" precision={2} currency="USD" />
                    </Column>
                    <Column
                        dataField="percent_of_avg_monthly_charges"
                        caption="% of Total Charges"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="percent" precision={1} />
                    </Column>
                    <Column
                        dataField="next30"
                        caption="Exp. 0-30 days"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="next60"
                        caption="Exp. 31-60 Days"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />
                    <Column
                        dataField="next90"
                        caption="Exp. 61-90 Days"
                        dataType="number"
                        allowGrouping={false}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        alignment={'center'}
                    />

                    <Summary calculateCustomSummary={calculateCustomSummary}>
                        <TotalItem
                            column="number_of_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="occupied_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="vacant_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            name="occupancy_percentage"
                            summaryType="custom"
                            displayFormat="{0}"
                            showInColumn="occupancy_percentage"
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />

                        <TotalItem
                            name="avg_sf"
                            summaryType="custom"
                            displayFormat="{0}"
                            showInColumn="avg_sf"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="total_sf"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="total_sf"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="occupied_notice"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="occupied_no_notice"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="notice_rented"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="vacant_unrented"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="vacant_rented"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="available_to_rent"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="leased_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="percent_of_total_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />

                        <TotalItem
                            name="average_total_monthly"
                            summaryType="custom"
                            showInColumn="average_total_monthly"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <TotalItem
                            name="average_total_monthly_psf"
                            summaryType="custom"
                            showInColumn="average_total_monthly_psf"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <TotalItem
                            column="total_monthly"
                            summaryType="sum"
                            showInColumn="total_monthly"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="atr_by_unit"
                            summaryType="custom"
                            showInColumn="atr_by_unit"
                            displayFormat="{0}"
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />
                        <TotalItem
                            column="percent_of_avg_monthly_charges"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="percent_of_avg_monthly_charges"
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />
                        <TotalItem
                            column="next30"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="next30"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <TotalItem
                            column="next60"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="next60"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <TotalItem
                            column="next90"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="next90"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <TotalItem
                            column="notice_available"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="notice_available"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <TotalItem
                            column="non_revenue_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="non_revenue_units"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <TotalItem
                            name="leased_percentage"
                            summaryType="custom"
                            displayFormat="{0}"
                            showInColumn="leased_percentage"
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />
                        <GroupItem column="fake" alignByColumn />
                        <GroupItem
                            name="occupancy_percentage"
                            summaryType="custom"
                            displayFormat="{0}"
                            showInColumn="occupancy_percentage"
                            alignByColumn
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />

                        <GroupItem
                            name="leased_percentage"
                            summaryType="custom"
                            displayFormat="{0}"
                            showInColumn="leased_percentage"
                            alignByColumn
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />
                        <GroupItem
                            name="atr_by_unit"
                            showInColumn="atr_by_unit"
                            summaryType="custom"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />
                        <GroupItem
                            name="group_notice_available"
                            column="notice_available"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <GroupItem
                            name="group_non_revenue_units"
                            column="non_revenue_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <GroupItem
                            name="group_next30"
                            column="next30"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <GroupItem
                            name="group_next60"
                            column="next60"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <GroupItem
                            name="group_next90"
                            column="next90"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <GroupItem
                            name="group_total_units"
                            column="number_of_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            name="group_occupied_units"
                            column="occupied_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            name="group_vacant_units"
                            column="vacant_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            name="avg_sf"
                            summaryType="custom"
                            displayFormat="{0}"
                            showInColumn="avg_sf"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="total_sf"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="total_sf"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="occupied_notice"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />
                        <GroupItem
                            column="notice_rented"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="occupied_no_notice"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="vacant_unrented"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="vacant_rented"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="available_to_rent"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="leased_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <GroupItem
                            column="percent_of_total_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="percent_of_total_units"
                            alignByColumn
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />

                        <GroupItem
                            name="average_total_monthly"
                            summaryType="custom"
                            showInColumn="average_total_monthly"
                            alignByColumn
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <GroupItem
                            name="average_total_monthly_psf"
                            summaryType="custom"
                            showInColumn="average_total_monthly_psf"
                            alignByColumn
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <GroupItem
                            column="total_monthly"
                            summaryType="sum"
                            showInColumn="total_monthly"
                            displayFormat="{0}"
                            alignByColumn
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <GroupItem
                            column="percent_of_avg_monthly_charges"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="percent_of_avg_monthly_charges"
                            alignByColumn
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />
                    </Summary>

                    <Grouping contextMenuEnabled autoExpandAll={expanded} />
                    <GroupPanel visible={!isReportWidget} />
                    <Export
                        enabled={!isReportWidget}
                        allowExportSelectedData={false}
                    />
                    <HeaderFilter
                        allowSelectAll={true}
                        allowSearch={true}
                        visible={!isReportWidget}
                    />
                    <ColumnChooser
                        enabled={!isReportWidget}
                        mode={'select'}
                        height={400}
                        allowSearch={true}
                    >
                        <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                        />
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <Toolbar>
                        <Item location="after" name="columnChooserButton" />
                        <Item name="groupPanel" />
                        <Item name="exportButton" visible={!isReportWidget} />
                        <Item location="before" visible={!isReportWidget}>
                            <ExpandAndCollapseButton
                                expanded={expanded}
                                toggleExpanded={toggleExpanded}
                                expandButtonEnable={expandButtonEnable}
                            />
                        </Item>
                    </Toolbar>
                    <SearchPanel
                        visible={true}
                        highlightCaseSensitive={false}
                        width={250}
                    />
                    <ColumnFixing enabled={true} />
                    <Paging enabled={true} defaultPageSize={60} />
                    <Scrolling mode="standard" />
                    <Pager
                        visible
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        allowedPageSizes={[20, 40, 60, 80, 100]}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} items)"
                    />
                    <FilterRow visible={!isReportWidget} applyFilter="auto" />
                    <FilterPanel visible={!isReportWidget} />
                    <StateStoring
                        enabled={true}
                        type="custom"
                        savingTimeout={100}
                        customSave={saveState}
                    />
                </DataGrid>
            </div>
        );
    },
);
export default UnitMixTable;
