import React, { useEffect } from 'react';
import { Drawer, Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { MenuLink } from 'components/menu/MenuItems';
import SettingsMenu from './user/SettingsMenu';
import FinancialsMenu from './navigation/FinancialsMenu';
import WorkflowsMenu from './navigation/WorkflowsMenu';
import DashboardsMenu from './navigation/DashboardsMenu';
import { usePermissions } from 'contexts';
import {
    overrideMenuItems,
    MenuSections,
    sidebarDrawerMenuTitle,
} from './AppLayoutUtils';
import { LeftOutlined } from '@ant-design/icons';

interface SidebarDrawerParams {
    selectedMenu: string;
    drawerOpen: boolean;
    onClose: () => void;
}

interface WindowZE {
    zE: {
        activate: () => void;
    };
}

const SidebarDrawer = ({
    selectedMenu,
    drawerOpen,
    onClose,
}: SidebarDrawerParams) => {
    const { pathname } = useLocation();
    const {
        financialMenuSettings,
        analyticsMenuSettings,
        leasingMenuSettings,
        planningMenuSettings,
    } = usePermissions();

    useEffect(() => {
        drawerOpen && onClose();
    }, [pathname]);

    const handleSupportClick = () => {
        if (Object.keys(window).includes('zE')) {
            // Show Zendesk Contact Us dialog
            (window as unknown as WindowZE).zE.activate();
        }
    };

    return (
        <Drawer
            placement="left"
            closable={true}
            onClose={onClose}
            closeIcon={<i className="fa-solid fa-arrow-left"></i>}
            headerStyle={{
                borderBottom: 0,
                paddingBottom: 0,
            }}
            mask={true}
            maskClosable={true}
            open={drawerOpen}
            getContainer={false}
            style={{
                position: 'absolute',
                top: 0,
                height: '100vh',
                zIndex: 5,
            }}
            width={240}
            bodyStyle={{ padding: 0 }}
        >
            {selectedMenu === MenuSections.Dashboards && (
                <DashboardsMenu
                    analyticsMenuSettings={analyticsMenuSettings}
                    leasingMenuSettings={leasingMenuSettings}
                    planningMenuSettings={planningMenuSettings}
                    pathname={pathname}
                />
            )}
            {selectedMenu === MenuSections.Financials && (
                <FinancialsMenu
                    menuSettings={financialMenuSettings}
                    pathname={pathname}
                />
            )}
            {selectedMenu === MenuSections.Workflows && (
                <WorkflowsMenu pathname={pathname} />
            )}
            {selectedMenu === MenuSections.Help && (
                <Menu mode="vertical" className={overrideMenuItems}>
                    {sidebarDrawerMenuTitle(MenuSections.Help)}
                    <Menu.Item key="h1">
                        <MenuLink
                            to="https://support.waypointbuilding.com/hc/en-us"
                            external={true}
                            onClick={onClose}
                        >
                            <p
                                style={{
                                    marginBottom: '10px',
                                    marginLeft: '-14px',
                                }}
                            >
                                Visit Help Center
                            </p>
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key="h2" onClick={() => handleSupportClick()}>
                        <p>Contact Support</p>
                    </Menu.Item>
                </Menu>
            )}
            {selectedMenu === MenuSections.Settings && (
                <SettingsMenu pathname={pathname} />
            )}
        </Drawer>
    );
};

export default SidebarDrawer;
