import useSWR from 'swr';
import { DataHookResponse } from './DataHookResponse';
import { GetKPIPayload, getKPIs } from 'waypoint-requests/kpis/getKPIs';
import { KPIResponse } from 'shared-types';

export const useGetKPIs = ({
    entityCodes,
    selected_data_level,
    date,
    kpi_requests,
}: GetKPIPayload): DataHookResponse<KPIResponse[]> => {
    const kpiRequestsToCacheString =
        entityCodes.length && kpi_requests.length
            ? kpi_requests
                  .map(({ kpi, yearOverYear, dateMode }) => {
                      return [kpi, dateMode, yearOverYear ? 'yoy' : undefined]
                          .filter((item) => !!item)
                          .join('__');
                  })
                  .sort()
                  .join(',')
            : null;

    const { data, error, mutate, isValidating } = useSWR(
        `/kpis/${entityCodes.sort().join(',')}/${selected_data_level.stakeholder}/${selected_data_level.percentageType}/${date}/${kpiRequestsToCacheString}`,
        () => getKPIs({ entityCodes, selected_data_level, date, kpi_requests }),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
